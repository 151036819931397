import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { base_url } from "../../config";
import RegistrationContext from "../../contexts/RegistrationContext";
import { ListCollapse, UsersRound } from "lucide-react";
import WhiteBoxLayout from "../../components/WhiteBoxLayout/WhiteBoxLayout";
import AddToCart from "../../components/AddToCart/AddToCart";
import "./style.scss";

const YesNo = ({ title, description = null, name, state, handler }) => {
  return (
    <Radios
      title={title}
      name={name}
      description={description}
      state={state}
      options={["Yes", "No"]}
      handler={handler}
    />
  );
};

const Radios = ({
  title,
  description = null,
  name,
  state,
  options,
  handler,
}) => {
  return (
    <>
      <div className="text-sm pr-4">
        {" "}
        <label htmlFor={name}>{title}</label>
        {description && (
          <div className="text-xs text-gray-500 mt-1">{description}</div>
        )}
      </div>
      <div className="flex gap-8">
        {options.map((option) => {
          return (
            <div className="flex items-center">
              <input
                id={`${name}-${option}`}
                type="radio"
                name={`${name}`}
                value={option}
                checked={state === option} // Comprueba si la opción "yes" está seleccionada
                onChange={() => handler(option)} // Maneja el cambio de selección
                className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500"
                required
              />
              <label
                htmlFor={`${name}-${option}`}
                className="ms-2 text-sm font-medium text-gray-900"
              >
                {option}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
};

function Extras() {
  const {
    setError,
    reload,
    setReload,
    data,
    clickedNext,
    setClickedNext,
    setCart,
    setStatus,
    setHighlightedCart,
    cart,
  } = useContext(RegistrationContext);
  const [participationReception, setParticipationReception] = useState(
    data.reception
  );
  const [cut, setCut] = useState(data.t_shirt_cut);
  const [size, setSize] = useState(data.t_shirt_size);
  const colors = false;
  const handleSubmit = async () => {
    setError("");
    try {
      // Make a POST request to your authentication endpoint
      await axios.post(`${base_url}/api/progress`, {
        reception: participationReception,
      });
      setReload(reload + 1);
    } catch (error) {
      setError("Unexpected error. " + error.response.data.message);
    }
  };

  const handleAddItem = async (item, params = null) => {
    try {
      // Make a POST request to your authentication endpoint

      const extra_params = params ? { params } : {};

      const response = await axios.post(`${base_url}/api/add_item`, {
        item,
        ...extra_params,
      });
      setStatus(
        "Item added to cart\nTo review or remove items from your basket, please click the basket icon in the top right corner. "
      );
      setHighlightedCart(true);
      setCart(response.data.cart);
    } catch (error) {
      setError("Unexpected error. " + error.response.data.message);
    }
  };

  useEffect(() => {
    if (clickedNext === 0) return;
    setClickedNext(0);
    handleSubmit();
  }, [clickedNext, handleSubmit, setClickedNext]);

  const GalaDinner = () => {
    const isGalaDinner = cart.items.some((item) => item.id === "gala_dinner");
    return (
      <>
        <div
          className={`w-full flex justify-between items-center mb-8 lg:mb-2 ${
            colors && "bg-purple-200"
          }`}
        >
          <div className="text-sm pr-4">
            Purchase a ticket for the gala dinner (€90)
          </div>
          <div>
            <AddToCart
              onClickHanlder={() => handleAddItem("gala_dinner")}
              disabled={isGalaDinner}
            />
          </div>
        </div>
      </>
    );
  };

  const ParticipationReception = () => {
    return (
      <div
        className={`w-full flex justify-between items-center mb-8 lg:mb-2 ${
          colors && "bg-purple-200"
        }`}
      >
        <YesNo
          title="Will you attend the reception event?"
          name="participation_reception"
          state={participationReception}
          description="The capacity is limited, please answer yes only if you are sure to attend."
          handler={(value) => setParticipationReception(value)}
        />
      </div>
    );
  };

  const IceBreaker = () => {
    const isIceBreaker = cart.items.some((item) => item.id === "icebreaker");
    return (
      <>
        <div
          className={`w-full flex justify-between items-center mb-8 lg:mb-2 ${
            colors && "bg-purple-200"
          }`}
        >
          <div className="text-sm pr-4">
            Purchase IceBreaker event ticket (Camino de Santiago)? (€30)
          </div>
          <div>
            <AddToCart
              onClickHanlder={() => handleAddItem("icebreaker")}
              disabled={isIceBreaker}
            />
          </div>
        </div>
      </>
    );
  };

  const AdditionalGalaDinner = () => {
    return (
      <>
        <div
          className={`w-full flex justify-between items-center mb-8 lg:mb-2 ${
            colors && "bg-purple-200"
          }`}
        >
          <div className="text-sm pr-4">
            Do you want additional tickets for the gala dinner for acompanying
            people (€90)?
          </div>
          <div>
            <AddToCart
              onClickHanlder={() => handleAddItem("additional_gala_dinner")}
            />
          </div>
        </div>
      </>
    );
  };

  const AdditionalReception = () => {
    return (
      <>
        <div
          className={`w-full flex justify-between items-center mb-8 lg:mb-2 ${
            colors && "bg-purple-200"
          }`}
        >
          <div className="text-sm pr-4">
            Do you want additional tickets for the reception (€20)?
          </div>
          <div>
            <AddToCart
              onClickHanlder={() => handleAddItem("additional_reception")}
            />
          </div>
        </div>
      </>
    );
  };

  const TShirtSelection = () => {
    const isTshirt = cart.items.some((item) => item.id === "t_shirt");
    const haveCutAndSize = !size || !cut;
    return (
      <div className={`w-full ${colors && "bg-yellow-200"}`}>
        {/* T-shirt container */}
        <div className="w-full flex items-center justify-between flex-col sm:flex-row">
          <div className="w-full lg:w-1/2 leading-tight tracking-tight text-gray-900 text-sm mb-4 lg:mb-0">
            Would you like an ECAI24 t-shirt? (20€): (
            <a
              href="../../../../images/male-tshirts-spec.jpg"
              target="_blank"
              className="text-blue-900 underline"
            >
              size chart
            </a>
            )
          </div>
          <div className="flex w-full sm:w-1/2 gap-4 justify-between sm:justify-end">
            <>
              <div>
                <select
                  id="cut"
                  name="cut"
                  defaultValue={cut}
                  className={"tshirt_select"}
                  onChange={(e) => setCut(e.target.value)}
                  required
                  disabled={isTshirt}
                >
                  <option value="">-- Cut --</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div>
                <select
                  id="size"
                  name="size"
                  defaultValue={size}
                  className={"tshirt_select"}
                  onChange={(e) => setSize(e.target.value)}
                  disabled={isTshirt}
                  required
                >
                  <option value="">-- Size --</option>
                  <option value="S">S</option>
                  <option value="M">M</option>
                  <option value="L">L</option>
                  <option value="XL">XL</option>
                  <option value="XXL">XXL</option>
                  <option value="3XL">3XL</option>
                </select>
              </div>
              <div>
                <AddToCart
                  onClickHanlder={() =>
                    handleAddItem("t_shirt", cut + "-" + size)
                  }
                  disabled={isTshirt || haveCutAndSize}
                />
              </div>
            </>
          </div>
        </div>
        {/* End T-shirt container */}
      </div>
    );
  };

  // const DietaryRequirements = () => {
  //   return (
  //     <div className={`w-full flex flex-col ${colors && "bg-amber-200"}`}>
  //       {/* Dietary requirements container  */}
  //       <div className="w-full">
  //         <div className="flex justify-between items-center">
  //           <div>
  //             <label
  //               htmlFor="dietary"
  //               className="block  text-sm font-medium text-gray-900"
  //             >
  //               Dietary requirements:{" "}
  //             </label>
  //           </div>
  //           <div className="w-1/2">
  //             <select
  //               id="dietary"
  //               name="dietary-requirements"
  //               required
  //               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
  //               defaultValue={dietary}
  //               onChange={(e) => setDietary(e.target.value)}
  //             >
  //               <option value="Regular">Regular</option>
  //               <option value="Vegetarian">Vegetarian</option>
  //               <option value="Vegan">Vegan</option>
  //               <option value="Gluten-free">Gluten-free</option>
  //               <option value="Lactose-free">Lactose-free</option>
  //               <option value="Kosher">Kosher</option>
  //               <option value="Halal">Halal</option>
  //             </select>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <WhiteBoxLayout title="Extras">
      <div className="space-y-4 md:space-y-6 flex justify-center">
        <form
          className="space-y-4 md:space-y-6 "
          style={{ width: "100%" }}
          method="POST"
          onSubmit={handleSubmit}
        >
          <div className="px-2">
            <div className="flex md:gap-8  mb-8" style={{ display: "block" }}>
              <div>
                <div className="w-full">
                  <div className="py-2 font-bold leading-tight tracking-tight text-gray-900 text-xl flex gap-2 border-b-slate-200 border-b mb-4">
                    <UsersRound /> Social Events
                  </div>
                  <div className=" w-full">
                    <div className="flex flex-col lg:flex-row sm:gap-10 md:gap-32 items-center pb-0 sm:pb-2">
                      <GalaDinner />
                    </div>
                    <div className="flex flex-col sm:flex-row sm:gap-10 md:gap-32 items-center pb-0 sm:pb-2">
                      <AdditionalGalaDinner />
                    </div>
                    {/*<div className="flex flex-col lg:flex-row sm:gap-10 md:gap-32 items-center pb-0 sm:pb-2">
                      <IceBreaker />
                    </div>*/}
                    <div className="flex flex-col lg:flex-row sm:gap-10 md:gap-32 items-center pb-0 sm:pb-2">
                      <ParticipationReception />
                    </div>
                    <div className="text-xs text-red-800">Reception event tickets are sold out. Answering yes will add you to a waiting list</div>
                  </div>
                </div>
              </div>
            </div>

            {/*<div className="flex md:gap-8" style={{ display: "block" }}>
              <div>
                <div className="w-full">
                  <div className="py-2 font-bold leading-tight tracking-tight text-gray-900 text-xl flex gap-2 border-b-slate-200 border-b mb-4">
                    <ListCollapse /> Additional Options
                  </div>
                  <div className=" w-full">
                    <div className="flex flex-col sm:flex-row sm:gap-10 md:gap-32 items-center pb-0 sm:pb-2">
                      <AdditionalReception />
                    </div>
                    <div className="flex flex-col sm:flex-row sm:gap-10 md:gap-32 items-center pb-0 sm:pb-2 mb-4 lg:mb-0">
                      <TShirtSelection />
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}
          </div>
        </form>
      </div>
    </WhiteBoxLayout>
  );
}

export default Extras;
