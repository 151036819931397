import React, { useState } from "react";
import axios from "axios";
import { useContext } from "react";
import RegistrationContext from "../contexts/RegistrationContext";
import { base_url } from "../config";

function ForgotPass() {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const {
    setScreen,
    setAuthenticated,
    setError,
    setStatus,
    reload,
    setReload,
  } = useContext(RegistrationContext);
  const handleSubmitEmail = async (event) => {
    event.preventDefault();

    // Reset login error state
    setError("");

    try {
      await axios.post(`${base_url}/api/forgot-password`, {
        email: email,
      });
      setCodeSent(true);
      setStatus("We have sent you a verification code to your email address");
    } catch (error) {
      setError("Password reset failed. Email not found");
    }
  };

  const handleSubmitCode = async (event) => {
    event.preventDefault();

    setError("");

    try {
      // Make a POST request to your authentication endpoint
      const response = await axios.post(
        `${base_url}/api/forgot-password-code`,
        {
          email,
          code,
        }
      );
      setAuthenticated(true);
      localStorage.setItem("token", response.data.access_token);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.access_token}`;
      setReload(reload + 1);
      setStatus("");
    } catch (error) {
      setStatus("");
      setError("Incorrect code: " + error.response.data.message);
    }
  };

  return (
    <>
      <div className="flex flex-row items-start justify-start py-8 mx-auto md:h-full lg:py-0 text-left">
        <div className="w-full bg-white md:mt-0 sm:max-w-md xl:p-0  ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              Forgot Password
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              action="#"
              onSubmit={!codeSent ? handleSubmitEmail : handleSubmitCode}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Your email
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  disabled={codeSent ? true : false}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5       "
                  placeholder="name@company.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {codeSent && (
                  <div className="mt-6">
                    <label
                      htmlFor="code"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Code:
                    </label>
                    <input
                      type="text"
                      id="code"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5       "
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      required
                    />
                  </div>
                )}
                <button className="button mt-6" type="submit">
                  {!codeSent ? "Remember" : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="flex flex-col px-6 py-8 mx-auto text-right">
          <button
            className="hover:underline"
            onClick={() => setScreen("login")}
          >
            {`<<`} Back
          </button>
        </div>
      </div>
    </>
  );
}

export default ForgotPass;
