/*
    Function that transforms the schedule code like 1011, for example, 
    and returns an object with these attributes:

    text: string, 
    saturday: boolean, 
    sunday: boolean, 
    days: number
*/
function scheduleTransform(schedule) {
  let text;
  let saturday = false;
  let sunday = false;
  let days = 1;

  switch (schedule) {
    case "0001":
      text = "Sunday Afternoon";
      saturday = false;
      sunday = true;
      days = 1;
      break;
    case "0010":
      text = "Sunday Morning";
      saturday = false;
      sunday = true;
      days = 1;
      break;
    case "0100":
      text = "Saturday Afternoon";
      saturday = true;
      sunday = false;
      days = 1;
      break;
    case "1000":
      text = "Saturday Morning";
      saturday = true;
      sunday = false;
      days = 1;
      break;
    case "0011":
      text = "Sunday Full day";
      saturday = false;
      sunday = true;
      days = 1;
      break;
    case "0101":
      text = "Saturday Afternoon and Sunday Afternoon";
      saturday = true;
      sunday = true;
      days = 2;
      break;
    case "1001":
      text = "Saturday Morning and Sunday Afternoon";
      saturday = true;
      sunday = true;
      days = 2;
      break;
    case "1010":
      text = "Saturday Morning and Sunday Morning";
      saturday = true;
      sunday = true;
      days = 2;
      break;
    case "0110":
      text = "Saturday Afternoon and Sunday Morning";
      saturday = true;
      sunday = true;
      days = 2;
      break;
    case "1100":
      text = "Saturday Full day";
      saturday = true;
      sunday = false;
      days = 1;
      break;
    case "1110":
      text = "Saturday Full day and Sunday Morning";
      saturday = true;
      sunday = true;
      days = 2;
      break;
    case "1101":
      text = "Saturday Full day and Sunday Afternoon";
      saturday = true;
      sunday = true;
      days = 2;
      break;
    case "1011":
      text = "Saturday Morning and Sunday Full day";
      saturday = true;
      sunday = true;
      days = 2;
      break;
    case "0111":
      text = "Saturday Afternoon and Sunday Full day";
      saturday = true;
      sunday = true;
      days = 2;
      break;
    case "1111":
      text = "Saturday Full day and Sunday Full day";
      saturday = true;
      sunday = true;
      days = 2;
      break;
    default:
      text = "";
      saturday = false;
      sunday = false;
      days = 0;
      break;
  }

  return { text: text, saturday: saturday, sunday: sunday, days: days };
}

export default scheduleTransform;
