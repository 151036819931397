import React, { useContext } from "react";
import "./style.scss";
import workshops from "../../util/workshops.json";

import { useEffect } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";

function Summary() {
  const { data } = useContext(RegistrationContext);

  useEffect(() => {
    window.history.replaceState({}, "", "/");
  }, []);
  const selected_workshops_list = [];

  workshops.forEach((workshop) => {
    if (
      data.workshop_selection &&
      data.workshop_selection.includes(workshop.id)
    ) {
      selected_workshops_list.push(workshop);
    }
  });

  return (
    <div className="flex flex-col h-full grow">
      <div
        style={{ maxWidth: "1152px", width: "100%" }}
        className="flex justify-start flex-col h-full items-center mx-auto lg:py-0 text-left"
      >
        <div
          style={{ maxWidth: "1152px", width: "100%", maxHeight: "100%" }}
          className="bg-white md:mt-0 sm:max-w-md xl:p-0  "
        >
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
            <h1 className="text-center px-10 pb-4 text-2xl">
              REQUEST CANCELLATION
            </h1>

            <div className="summary-card">
            <p>
              To cancel your registration, written notification must be sent by email to <a className="font-bold text-blue-950 " href="mailto:registration@ecai2024.eu"> registration@ecai2024.eu</a>
              </p>
              <p>In your email, you should acknowlede that you are aware of the cancellation fees.</p>

<p className="mt-2">The following refund conditions apply:</p>
<ul className="list-disc list-inside">
<li className="mt-2">If the registration is the only registration covering a given paper, then no refund is possible.</li>

<li className="mt-2">In case of cancellation on or before 19 August 2024, the registration fee will be refunded with a deduction of € 50 as administration fee.</li>

<li className="mt-2">In case of cancellation after 19 August 2024 but on or before 3 October 2024, the registration fee will be refunded with a deduction of 50% of the price as cancellation fee. The registration fee will not be refunded for cancellations received after 3 October 2024 or in case of no-shows.</li>
</ul>          
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
