import React, { useContext, useState } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import "./style.scss";
import { FileSearch, CirclePlus } from "lucide-react";
import papers from "../../util/papers.json";

function SearchPapers() {
  const [filteredPapers, setFilteredPapers] = useState(papers);
  const { paperSelected, setPaperSelected, setPaperOpen } =
    useContext(RegistrationContext);

  const paperClicked = (paperIdValue) => {
    setPaperOpen(false);
    setPaperSelected([...paperSelected, { paperId: paperIdValue }]);
  };

  // function to filter papers based on search input
  const filterPapers = (event) => {
    const searchInput = event.target.value;
    const filteredPapers = papers.filter((paper) => {
      return (
        paper.title.toLowerCase().includes(searchInput.toLowerCase()) ||
        paper.id.toString().includes(searchInput.toLowerCase())
      );
    });
    setFilteredPapers(filteredPapers);
  };

  return (
    <div className="resetForm  paper-input-container flex flex-col justify-center px-6 py-8 mx-auto h-full lg:py-0 text-left">
      <div className="flex text-white gap-4">
        <div style={{ color: "#20334d" }}>
          <FileSearch size={30} />
        </div>
        <div className="sectionTitle" style={{ color: "#20334d" }}>
          Add a paper to your registration
        </div>
      </div>
      <div className="my-8">
        <form className="max-w-md mx-auto">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                aria-hidden="true"
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 19l-4-4m0-7A7 7 0 111 8a7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Type your paper Title or ID"
              onChange={(event) => filterPapers(event)}
              required
            />
          </div>
        </form>
      </div>
      <div className="w-full h-full bg-white shadow  md:mt-0 sm:max-w-md xl:p-0 overflow-y-auto ">
        <div className="w-full p-6 space-y-4 md:space-y-6 sm:p-8   text-xs">
          {filteredPapers.map((paper) => {
            return (
              <div key={paper.id} className="flex flex-col gap-4 ">
                <div className="flex gap-4 ">
                  <div className="bg-sky-950 text-white px-2 py-2 h-8">
                    {paper.id}
                  </div>
                  <div className="text-black flex-grow">{paper.title}</div>
                  <div className="text-black cursor-pointer">
                    {" "}
                    <CirclePlus
                      size="14"
                      onClick={() => paperClicked(paper.id)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default SearchPapers;
