import React, { useContext, useEffect, useRef, useState } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import "./style.scss";
import { ShoppingCart, Trash2 } from "lucide-react";
import axios from "axios";
import { base_url } from "../../config";

function Cart() {
  const {
    data,
    reload,
    setCartOpen,
    cart,
    setReload,
    setError,
    setCart,
    setPaperSelected,
    paperSelected,
    extraPages,
    setExtraPages,
  } = useContext(RegistrationContext);

  const [inCommunication, setInCommunication] = useState(false);
  const removeItem = async (item_type, param = null) => {
    if (inCommunication) return;
    setInCommunication(true);
    setError("");
    try {
      let item = {};
      if (item_type === "paper") {
        item_type = "papers";
        item = { ...item, item: param };
      }
      if (item_type === "extra_pages") {
        item_type = "extra_pages";
        item = { ...item, item: param };
      }
      const response = await axios.post(`${base_url}/api/remove_item`, {
        item_id: item_type,
        ...item,
      });
      setCart(response.data.cart);
      if (item_type === "papers") {
        setPaperSelected(paperSelected.filter((p) => p !== param));
      }
      if (item_type === "extra_pages") {
        const newExtraPages = { ...extraPages };
        delete newExtraPages[param];
        setExtraPages(newExtraPages);
      }
    } catch (error) {
      //setError("Unexpected error. " + error.response.data.message);
    }
    setInCommunication(false);
  };

  if (!cart || !cart.items) {
    return (
      <div
        className="button w-24 ml-auto mb-8"
        onClick={() => setCartOpen(false)}
      >
        {" "}
        Close{" "}
      </div>
    );
  }
  const insert_emtpy_rows =
    10 - cart.items.length > 0 ? 10 - cart.items.length : 0;
  return (
    <div className="cart-container flex flex-col w-full max-w-full text-left lg:px-6 md:py-8 sm:mx-auto lg:w-auto md:h-full">
      <div className="flex gap-4">
        <div style={{ color: "#20334d" }}>
          <ShoppingCart size={30} />
        </div>
        <div
          className="sectionTitle"
          style={{ color: "#20334d", marginBottom: "50px" }}
        >
          Shopping Cart
        </div>
      </div>
      <div className=" cartInfo cart-input-container flex flex-col items-center w-full max-w-full lg:w-auto lg:mx-auto lg:py-0 text-left">
        <div className="bg-white shadow w-full md:mt-0 sm:max-w-full p-6 flex flex-col ">
          <div
            style={{
              height: "40px",
              width: "100%",
              fontWeight: "bold",
              borderBottom: "2px solid black",
            }}
            className="flex"
          >
            <div style={{ width: "370px" }}>Item</div>
            <div
              style={{
                textAlign: "center",
                width: "45px",
              }}
            >
              Qty
            </div>
            <div
              style={{
                textAlign: "center",
                width: "45px",
              }}
            >
              Price
            </div>
          </div>

          <div className="space-y-4 md:space-y-6 pt-6 flex flex-col grow ">
            <div
              style={{
                maxWidth: "500px",
                margin: "0 auto",
                width: "100%",
                minHeight: "200px",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "370px" }}>
                    <b>{cart.main && cart.main.type}</b>
                  </div>
                  <div> </div>
                  <div style={{ textAlign: "center", width: "45px" }}>1</div>
                  <div
                    style={{ textAlign: "right", width: "45px" }}
                    className="integer-part"
                  >
                    €{cart.main && Math.floor(cart.main.price)}
                  </div>
                </div>
               
                {cart.items &&
                  cart.items.map((item, index) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "8px",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          width: "285px",
                          flexGrow: "1",
                        }}
                      >
                        {item.description}
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          height: "100%",
                          paddingLeft: "10px",
                        }}
                      >
                        <div
                          onClick={() =>
                            removeItem(
                              item.type,
                              item.type == "paper" || item.type == "extra_pages"
                                ? item.paper_id
                                : null
                            )
                          }
                          className="p-1 cursor-pointer bg-blue-50 rounded-lg text-sky-950 flex align-middle items-center hover:text-red-600"
                        >
                          <Trash2 size="16" />
                          <span className="text-xs">Remove</span>
                        </div>
                      </div>
                      <div style={{ textAlign: "center", width: "45px" }}>
                        {item.quantity}
                      </div>
                      <div
                        style={{ textAlign: "right", width: "45px" }}
                        className="integer-part"
                      >
                        €{Math.floor(item.price)}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              height: "40px",
              display: "flex",
              alignItems: "end",
            }}
          >
            <div colSpan={3} style={{ textAlign: "right" }}>
              <b>Total: </b>
            </div>
            <div
              style={{ textAlign: "right" }}
              className="integer-part text-bold"
            >
              <b>€{Math.floor(cart.total_price)}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cart;
