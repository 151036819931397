import React, { useContext, useEffect } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import "./style.scss";

function StatusBar({ noshow = false }) {
  const { error, setError, status, setStatus } =
    useContext(RegistrationContext);

  const handleCloseError = () => {
    setError(null);
  };
  const handleCloseStatus = () => {
    setStatus(null);
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  useEffect(() => {
    if (status) {
      const timer = setTimeout(() => {
        setStatus(null);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [status]);

  return (
    <>
      <div
        className="absolute top-12 left-1/2 transform -translate-x-1/2"
        style={{
          maxWidth: "1280px",
          paddingLeft: "100px",
          paddingRight: "100px",
          width: "100%",
        }}
      >
        {error && (
          <div
            className="bg-red-100 w-full border-1 border-red-400 text-red-700 px-4 py-3 rounded relative text-center absolute"
            role="alert"
          >
            <span className="block sm:inline">{error}</span>
            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
              <svg
                className="fill-current h-6 w-6 text-red-500 cursor-pointer"
                onClick={handleCloseError}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </div>
        )}
        {status && (
          <div
            className="ease-in-out bg-blue-100 w-full border border-blue-400 text-blue-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <div className="flex justify-center">
              <span className="block sm:inline">{status}</span>
            </div>

            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
              <svg
                className="fill-current h-6 w-6 text-blue-700 cursor-pointer"
                onClick={handleCloseStatus}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </div>
        )}
      </div>
    </>
  );
}

export default StatusBar;
