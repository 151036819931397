import { useContext } from "react";
import RegistrationContext from "../contexts/RegistrationContext";
import LoginForm from "../pages/LoginForm/LoginForm";
import Signin from "../pages/Signin";
import ForgotPass from "../pages/ForgotPass";
import Spinner from "../components/Spinner/Spinner";

const NotAuthenticated = () => {
  const { screen, loading } = useContext(RegistrationContext);

  return (
    <>
      <div className="maincnt bg-custombg ">
        <div className="main  ">
          {loading ? (
            <div className="flex justify-center h-full items-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div
                className="flex flex-col mt-10 py-2 sm:py-10 px-8"
                style={{ backgroundColor: "#f0f7ff" }}
              >
                <h2
                  className="text-4xl"
                  style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
                >
                  ECAI 2024 registration
                </h2>
              </div>

              <div className="flex flex-wrap justify-center">
                <div className="grow w-full md:w-auto">
                  {screen === "login" && <LoginForm />}
                  {screen === "signin" && <Signin />}
                  {screen === "forgotpass" && <ForgotPass />}
                </div>
                <div className="registration-logo-wrapper">
                  <a href="http://ecai2024.eu" target="_blank" rel="noreferrer">
                    <img
                      src="../../../../images/logo-ecai.svg"
                      alt="ECAI-LOGO"
                      id="ecaiLogo"
                    />
                  </a>
                  <a
                    href="https://www.usc.gal/en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="../../../../images/logo-usc.svg"
                      alt="USC-LOGO"
                      id="uscLogo"
                      style={{ width: "5rem" }}
                    />
                  </a>
                  <a
                    href="https://citius.gal/es/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="../../../../images/logo-citius.svg"
                      alt="CITIUS-LOGO"
                      id="citiusLogo"
                      style={{ width: "14rem" }}
                    />
                  </a>
                  <a
                    href="https://www.aepia.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="../../../../images/logo-aepia.png"
                      alt="AEPIA-LOGO"
                      id="aepiaLogo"
                      style={{ width: "7rem" }}
                    />
                  </a>
                  <a href="https://eurai.org/" target="_blank" rel="noreferrer">
                    <img
                      src="../../../../images/eurai-logo.svg"
                      style={{ width: "6rem" }}
                      alt="EURAI-LOGO"
                      id="eurailLogo"
                    />
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NotAuthenticated;
