import React, { useContext, useState } from "react";
import "./style.scss";
import RegistrationContext from "../../contexts/RegistrationContext";
import axios from "axios";
import { base_url } from "../../config";

function MyPreferences() {
  const { data, setData, setError, setStatus } =
    useContext(RegistrationContext);
  const [specialRequirements, setSpecialRequirements] = useState(
    data.special_requirements
  );
  const [sponsorsCheck, setSponsorsCheck] = useState(data.share_data_sponsors);

  const hasChanged = () => {
    return (
      sponsorsCheck !== data.share_data_sponsors ||
      specialRequirements !== data.special_requirements
    );
  };

  const handleSubmit = async (event) => {
    try {
      // Make a POST request to your preferences endpoint
      event.preventDefault();
      const requestData = {
        special_requirements: specialRequirements,
        share_data_sponsors: sponsorsCheck,
      };
      const response = await axios.post(
        `${base_url}/api/preferences`,
        requestData
      );
      setData({
        ...data,
        share_data_sponsors: requestData.share_data_sponsors,
        special_requirements: requestData.special_requirements,
      });
      setStatus("Store data succeeded!");
    } catch (error) {
      setError("Unexpected error. " + error.response.data.message);
    }
  };

  return (
    <div className="flex flex-col h-full grow">
      <div
        style={{ maxWidth: "1152px", width: "100%" }}
        className="flex justify-start flex-col h-full items-center mx-auto lg:py-0 text-left"
      >
        <div
          style={{ maxWidth: "1152px", width: "100%", maxHeight: "100%" }}
          className="bg-white md:mt-0 sm:max-w-md xl:p-0  "
        >
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
            <h1 className="text-center px-10 pb-4 text-2xl">MY PREFERENCES</h1>
            <div className="summary-card">
              <h2 className="mb-2">SPECIAL REQUIREMENTS</h2>

              <div className="mb-8 pb-2">
                <label
                  htmlFor="specialRequirements"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Disabilities/Special Requirements/Dietary requirements
                </label>
                <input
                  type="text"
                  id="specialRequirements"
                  name="specialRequirements"
                  placeholder="If you have any special requirements, please let us know."
                  value={specialRequirements}
                  onChange={(e) => setSpecialRequirements(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                />
              </div>

              <h2 className="mb-2">
                SHARE MY CONTACT WITH CONFERENCE SPONSORS
              </h2>

              <div className="items-center">
                <div className="text-sm my-2">
                  <label htmlFor="sponsors">
                    I agree to share my contact data with the conference
                    sponsors regarding activities related to ECAI 2024.:{" "}
                    <span className="text-red-600">*</span>
                  </label>
                </div>

                <div className="flex gap-8 bg-gray-50 border border-gray-300 px-2.5 py-0.5 mb-4">
                  <div className="py-2 flex items-center">
                    <input
                      id="sponsors-yes"
                      type="radio"
                      name="sponsors"
                      value="Yes"
                      checked={sponsorsCheck === "Yes"} // Comprueba si la opción "yes" está seleccionada
                      onChange={() => setSponsorsCheck("Yes")} // Maneja el cambio de selección
                      className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 focus:ring-amber-500"
                      required
                    />
                    <label
                      htmlFor="sponsors-yes"
                      className="ms-2 text-sm font-medium text-gray-900"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="py-2 flex items-center">
                    <input
                      id="sponsors-no"
                      type="radio"
                      name="sponsors"
                      value="No"
                      checked={sponsorsCheck === "No"}
                      onChange={() => setSponsorsCheck("No")}
                      className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500"
                      required
                    />
                    <label
                      htmlFor="sponsors-no"
                      className="ms-2 text-sm font-medium text-gray-900"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="mt-8 text-center">
                <button
                  onClick={(e) => handleSubmit(e)}
                  disabled={!hasChanged()}
                  className={`button mt-6 ${
                    !hasChanged() ? "opacity-40 !cursor-default" : ""
                  }`}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPreferences;
