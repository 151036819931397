import React, { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./style.scss";
import { base_url, stripe_key } from "../../config";
import RegistrationContext from "../../contexts/RegistrationContext";
import FreePayment from "./FreePayment";
import axios from "axios";
import { ShoppingCart, Trash2 } from "lucide-react";
import AlertDialog from "../../components/AlertDialog/AlertDialog";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(stripe_key);

export default function App() {
  const { data, setStatus, setError, setCartOpen, setCart, cart } = useContext(RegistrationContext);
  const [clientSecret, setClientSecret] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [loading, setIsLoading] = useState(true);
  const [codeApplied, setCodeApplied] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");


  const removeCode = async () => {
    try {
      const response = await axios.post(`${base_url}/api/remove_item`, {
        item_id: 'code'
        
      });
      setCart(response.data.cart);
    } catch (error) {
    }
  }

  const handleApplyCode = () => {
    axios
      .post(`${base_url}/api/apply_code`, {
        code: discountCode,
      })
      .then((res) => {
        setCart(res.data.cart);
        if (res.data.warning) {
          setAlertMessage(res.data.warning);
        }

      })
      .catch((err) => {
        setError("Error: bad discount code.");
      });
  };
  useEffect(() => {
    setIsLoading(true);
    setDiscountCode(cart.items.filter(a => a.id === "code")[0]?.code_id || "");

    setCodeApplied(cart.items.filter(a => a.id === "code").length > 0);
    if (cart.total_price == 0) {
      setClientSecret("free");
      setIsLoading(false);
      return
    }

    // Create PaymentIntent as soon as the page loads
    axios
      .post(`${base_url}/api/checkout`, { items: [{ id: `Reg. ${data.id}` }] })
      .then((res) => {
        setIsLoading(false);
        setClientSecret(res.data.clientSecret);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [cart]);



  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const closeDialog = () => {
    setAlertMessage("");
  }
  return (
    <>
      {alertMessage && (
        <AlertDialog setOpen={closeDialog}>
          {alertMessage}
        </AlertDialog>

      )}
      <div className="flex flex-col justify-center">
        <div
          className="discountForm-wrapper flex justify-center bg-teal-100 p-2 rounded-lg flex-col lg:flex-row"
          style={{
            backgroundColor: "#cce1f8",
            margin: "auto",
            padding: "10px 2em",
            color: "#20334d",
          }}
        >
          Please before proceed with the payment &nbsp;
          <a
            style={{
              color: "#20334d",
              fontWeight: "bold",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => setCartOpen(true)}
            className="mt-2 lg:mt-0"
          >
            <div className="flex gap-2">
              double-check your cart <ShoppingCart />
            </div>
          </a>
        </div>
        <div className="discountForm-wrapper flex justify-center">
          <div className="discountForm flex justify-between items-center gap-4 flex-col lg:flex-row lg:gap-0">
            <div>Have discount code?</div>

            <form>
              <input
                placeholder="Introduce your code"
                className="discountInput"
                onChange={e => setDiscountCode(e.target.value)}
                value={discountCode}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault(); 
                    handleApplyCode(); 
                  }
                }}
                disabled={codeApplied}
                
              ></input>
            </form>
            {codeApplied ? (
              <button onClick={() =>removeCode () } className="buttonDiscount">
              <Trash2/>
            </button>
            ) : (
              <button onClick={handleApplyCode} className="buttonDiscount">
                Apply
              </button>
            )}

          </div>
        </div>

        {clientSecret &&

          !loading &&
          (cart.total_price > 0 ? (
            <>
              {1 && (
                <div className="payment">
                  <Elements options={options} stripe={stripePromise}>

                    <CheckoutForm />
                  </Elements>
                </div>
              )}
            </>

          ) : (
            <div className="">

              <FreePayment />
            </div>
          ))}
      </div>
    </>
  );
}
