import { useContext, useEffect } from "react";
import RegistrationContext from "./contexts/RegistrationContext";
import setAuthToken from "./authtoken";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import StatusBar from "./components/StatusBar/StatusBar";
import axios from "axios";
import { base_url } from "./config";
import parseAnswer from "./util/parseAnswer";
import NotAuthenticated from "./sections/NotAuthenticated";
import RegularUser from "./sections/RegularUser";
import AdminUser from "./sections/AdminUser";
import RegistrationComplete from "./sections/RegistrationComplete";
import Loading from "./pages/Loading/Loading";

function App() {
  const {
    screen,
    authenticated,
    setAuthenticated,
    reload,
    setScreen,
    setMaxStep,
    setData,
    setError,
    setStatus,
    setReload,
    setCart,
    setEmail,
    setPhase,
    setPaperSelected,
    setPaperSelectedVerified,
    setIsAdmin,
    loading,
    setLoading,
    setIsStudent,
    setIsMainAccess,
    setOptionAccess,
    setPrices,
    setExtraPages,
    next,
    setNext,
    setIsImpersonating,
    setUpgradableOptions
  } = useContext(RegistrationContext);

  const token = localStorage.getItem("token");
  if (token) {
    setAuthenticated(true);
    setAuthToken(token);
  }

  useEffect(() => {
    setLoading(true);

    axios
      .get(`${base_url}/api/progress`)
      .then((response) => {
        setScreen(parseAnswer(response));
        setMaxStep(response.data.max_step);
        if (response && response.data.data) {
          setData(response.data.data);
          if (response.data.data.papers) {
            setPaperSelected(response.data.data.papers);
            setPaperSelectedVerified(response.data.data.registered_papers);
          } else {
            setPaperSelected("");
            setPaperSelectedVerified("");
          }
          if (response.data.data.extra_pages) {
            setExtraPages(response.data.data.extra_pages);
          } else {
            setExtraPages({});
          }
        }
        if (response && response.data.cart) {
          setCart(response.data.cart);
        }
        setEmail(response.data.email);
        setPhase(response.data.phase);
        setIsImpersonating(response.data.impersonating);
        if (response && response.data.pr) {
          setPrices(JSON.parse(atob(response.data.pr)));
        }
        setIsStudent(response.data.type === "student");
        setIsMainAccess(response.data.option === "main");
        setOptionAccess(response.data.option);
        setLoading(false);
        setIsAdmin(response.data.role === "admin");
        setUpgradableOptions([...response.data.upgradable_options]);
        
        if (next) {
          setScreen(next);
          setNext(null);
        }
      })
      .catch(() => {
        setLoading(false);
        setAuthToken("");

        localStorage.removeItem("token");
        setScreen("login");
        setData({});
        setError("");
        setStatus("");
        setMaxStep("");
      });
  }, [
    reload,
    setScreen,
    setAuthenticated,
    setError,
    setStatus,
    setReload,
    setMaxStep,
    setData,
    setEmail,
    setCart,
    setIsAdmin,
    setPaperSelected,
    setPhase,
    setLoading,
    setIsMainAccess,
    setIsStudent,
    setOptionAccess,
    setPrices,
  ]);

  useEffect(() => {
    if (!authenticated) {
      async function fetchData() {
        try {
          const response = await axios.post(`${base_url}/api/get_phase`);
          setPhase(response.data.phase);
        } catch (error) {
          setError("Network error");
        }
      }
      fetchData();
    }
  }, [authenticated, setError, setPhase]);

  useEffect(() => setLoading(true), [setLoading]);

  const isAdminScreen = screen.startsWith("admin");
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="App">
      {/* Main header with sign in, log out, etc */}
      <div className="nav">
      <NavBar boxed={!isAdminScreen} />
      </div>

      {/* To show messages like a snack bar */}
      <div>
        <StatusBar />
      </div>

      {!authenticated && <NotAuthenticated />}
      {authenticated && !isAdminScreen && screen !== "Confirmation" && (
        <RegularUser />
      )}
      {authenticated && !isAdminScreen && screen === "Confirmation" && (
        <RegistrationComplete />
      )}
      {authenticated && isAdminScreen && <AdminUser />}

      <div>
        {!isAdminScreen && <Footer />} 
        
      </div>
    </div>
  );
}

export default App;
