import React from "react";
import "./style.scss";
import {Trash2} from "lucide-react";

async function downloadFile(fileUrl, fileName) {
  const token = localStorage.getItem("token");
  const response = await fetch(fileUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();

  // The timeout is necessary in some browsers to revoke the object URL after creating the download
  setTimeout(() => window.URL.revokeObjectURL(url), 100);
}

// Function to upload a file
const FileUpload = ({
  id,
  fileSource,
  children,
  handleUploadFile,
  handleRemoveFile,
  backendFile,
  backendFilePath,
  backendFileHandler,
  onError
}) => {
  const colors = false;
  return (
    <div className={`text-sm w-full ${colors && "bg-purple-200"}`}>
      {children}
      <div className="flex justify-start">
        {!fileSource && !backendFile && (
          <input
            id={id}
            type="file"
            name="file"
            accept=".pdf"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file && file.size > 4 * 1024 * 1024) {
                // 4MB
                onError("File too large. Max size is 4MB");
                e.target.value = "";
              } else {
                handleUploadFile(file);
              }
            }}
          />
        )}

        {fileSource && (
          <>
            <a
              href={URL.createObjectURL(fileSource)}
              download={fileSource.name}
              className=""
            >
              {fileSource.name}
            </a>
            <div className="ml-2 inset-y-0 right-0 pr-3  flex items-center cursor-pointer text-gray-500 hover:text-red-600">
              <Trash2 onClick={() => handleRemoveFile()} size="16" />
            </div>
          </>
        )}

        {!fileSource && backendFile && (
          <>
            <div
              className="cursor-pointer"
              onClick={() => downloadFile(backendFilePath, backendFile, handleUploadFile)}
            >
              {backendFile}
            </div>
            <div className="ml-2 inset-y-0 right-0 pr-3  flex items-center cursor-pointer text-gray-500 hover:text-red-600">
              <Trash2 onClick={() => backendFileHandler(null)} size="16" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};


export default FileUpload;
