import { useContext } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import Logout from "../../pages/Logout";
import "./style.scss";
import CartIcon from "../CartIcon/CartIcon";
import LeaveImpersonation from "../../components/LeaveImpersonation";
import { User, UserCog } from "lucide-react";

function AuthStatus() {
  const {
    authenticated,
    screen,
    setScreen,
    maxStep,
    setUserInfoOpen,
    isAdmin,
    isImpersonating
  } = useContext(RegistrationContext);

  const showCart =
    screen !== "Registration Type" && maxStep !== "Confirmation";
  const isAdminScreen = screen.startsWith("admin");
  if (authenticated) {
    return (
      <div className="flex">
        {!isAdminScreen && (
          <>
            <div className="mr-6">
              <User
                onClick={() => {
                  setUserInfoOpen(true);
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
            {showCart && (
              <div className="mr-6">
                <CartIcon />
              </div>
            )}
          </>
        )}
        {isAdmin && (
          <div className="mr-6 text-red-500 cursor-pointer">
            <UserCog
              onClick={() => {
                setScreen(isAdminScreen ? maxStep : "admin-dashboard");
              }}
            />
          </div>
        )}
      {isImpersonating ? (
          <div className="ml-6">
            <LeaveImpersonation />
          </div>
        ) : (
          <div className="ml-6">
            <Logout />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="leading-none pb-2">
        <button
          className="leading-none font-bold"
          onClick={() => setScreen("signin")}
        >
          Sign up
        </button>
      </div>
    );
  }
}

export default AuthStatus;
