import { ShoppingCart } from "lucide-react";
import { useContext } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import axios from "axios";
import { base_url } from "../../config";
import "./style.scss";

function CartIcon({ dropdownRef2 }) {
  const {
    reload,
    setReload,
    setError,
    setCartOpen,
    cartAdded,
    cart,
    highlightedCart,
    setHighlightedCart,
  } = useContext(RegistrationContext);

  const handler = async (step) => {
    setError("");
    try {
      await axios.post(`${base_url}/api/jump`, {
        step: "Cart",
      });
      setReload(reload + 1);
    } catch (error) {
      setError("Not accessible step");
    }
  };

  return (
    <div
      className=" flex gap-2"
      onClick={() => {
        setCartOpen(true);
        setHighlightedCart(false);
      }}
    >
      <div className="cart-icon cursor-pointer flex relative">
        <ShoppingCart
          ref={dropdownRef2}
          size={24}
          className={`${cartAdded && "clicked"}`}
        />
        <span
          class="absolute flex h-3 w-3"
          style={{ top: "-5px", left: "8px" }}
        >
          {highlightedCart && (
            <>
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-amber-400 opacity-75"></span>
              <span class="relative inline-flex rounded-full h-3 w-3 bg-amber-500"></span>
            </>
          )}
        </span>
      </div>
      <div className="cursor-pointer total-cart-chip">
        Total: <span className="font-bold">€{cart.total_price}</span>
      </div>
    </div>
  );
}

export default CartIcon;
