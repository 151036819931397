import React, { useContext, useState } from "react";
import RegistrationContext from "../contexts/RegistrationContext";
import SidePanel from "../components/SidePanel/SidePanel";
import Cart from "../pages/Cart/Cart";
import UserInfo from "../pages/UserInfo/UserInfo";
import SearchPapers from "../components/SearchPapers/SearchPapers";
import Spinner from "../components/Spinner/Spinner";
import Summary from "../pages/Summary/Summary";
import RequestCancellation from "../pages/Summary/RequestCancellation";
import UpdateRegistration from "../pages/Summary/UpdateRegistration";
import Billing from "../pages/Summary/Billing";
import Logout from "../pages/Logout";
import InvitationLetter from "../pages/Summary/InvitationLetter";
import MyPreferences from "../pages/Summary/MyPreferences";

const RegistrationComplete = () => {
  const {
    loading,
    cartOpen,
    setCartOpen,
    userInfoOpen,
    setUserInfoOpen,
    paperOpen,
    setPaperOpen,
    cart,
    upgradableOptions,
  } = useContext(RegistrationContext);

  const [summarySelectedMenu, setSummarySelectedMenu] = useState({
    menuItem: "summary",
    content: <Summary />,
  });

  return (
    <>
      <SidePanel isOpen={cartOpen} openCloseHandler={setCartOpen}>
        <Cart />
      </SidePanel>

      <SidePanel isOpen={userInfoOpen} openCloseHandler={setUserInfoOpen}>
        <UserInfo />
      </SidePanel>

      <SidePanel isOpen={paperOpen} openCloseHandler={setPaperOpen}>
        <SearchPapers />
      </SidePanel>

      <div className="maincnt bg-custombg flex flex-row">
        <div className="main flex flex-col lg:flex-row">
          <div className="summary-menu">
            <div className="mb-7 w-36">
              <a href="http://ecai2024.eu" target="_blank" rel="noreferrer">
                <img
                  src="../../../../images/logo-ecai.svg"
                  alt="ECAI-LOGO"
                  id="ecaiLogo"
                  className="w-full"
                />
              </a>
            </div>

            <ul>
              <li
                onClick={(e) => {
                  setSummarySelectedMenu({
                    menuItem: "summary",
                    content: <Summary />,
                  });
                }}
                className={`${
                  summarySelectedMenu.menuItem === "summary" ? "selected" : ""
                }`}
              >
                Summary
              </li>
              {upgradableOptions.length > 0 && (
                <li
                  onClick={(e) => {
                    setSummarySelectedMenu({
                      menuItem: "update-registration",
                      content: <UpdateRegistration />,
                    });
                  }}
                  className={`${
                    summarySelectedMenu.menuItem === "update-registration"
                      ? "selected"
                      : ""
                  }`}
                >
                  Update Registration
                </li>
              )}
              {cart.total_price > 0 && (
                <li
                  onClick={(e) => {
                    setSummarySelectedMenu({
                      menuItem: "billing",
                      content: <Billing />,
                    });
                  }}
                  className={`${
                    summarySelectedMenu.menuItem === "billing" ? "selected" : ""
                  }`}
                >
                  Billing
                </li>
              )}
              <li
                onClick={(e) => {
                  setSummarySelectedMenu({
                    menuItem: "invitation_letter",
                    content: <InvitationLetter />,
                  });
                }}
                className={`${
                  summarySelectedMenu.menuItem === "invitation_letter"
                    ? "selected"
                    : ""
                }`}
              >
                Invitation Letter
              </li>
              <li
                onClick={(e) => {
                  setSummarySelectedMenu({
                    menuItem: "request-cancellation",
                    content: <RequestCancellation />,
                  });
                }}
                className={`${
                  summarySelectedMenu.menuItem === "request-cancellation"
                    ? "selected"
                    : ""
                }`}
              >
                Request Cancellation
              </li>
              <li
                onClick={(e) => {
                  setSummarySelectedMenu({
                    menuItem: "my-preferences",
                    content: <MyPreferences />,
                  });
                }}
                className={`${
                  summarySelectedMenu.menuItem === "my-preferences"
                    ? "selected"
                    : ""
                }`}
              >
                My preferences
              </li>
              <li>
                <Logout color="#000" />
              </li>
            </ul>
          </div>

          {loading ? (
            <div className="flex justify-center h-full items-center">
              <Spinner />
            </div>
          ) : (
            <>{summarySelectedMenu.content}</>
          )}
        </div>
      </div>
    </>
  );
};

export default RegistrationComplete;
