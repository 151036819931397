import { useId, useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import "./style.css";
import { Box, Tab, Tabs } from "@mui/material";
import BadgesFilter from "../../components/BadgesFilter/BadgesFilter";
import axios from "axios";
import { base_url } from "../../config";
import { RiFileExcel2Line } from "@remixicon/react";

function ListPapers() {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [filters, setFilters] = useState([]);


  useEffect(() => {
    axios.get(`${base_url}/api/admin/papers`).then((response) => {
      setData(response.data);
      setOriginalData(response.data);
    });
  }, []);


  const downLoadExcel = async () => {
    try {
      const response = await axios.get(`${base_url}/api/admin/export_excel_papers`, {
        responseType: "blob",
      });
      const pdfBlob = new Blob([response.data], { type: "application/xlsx" });

      const url = window.URL.createObjectURL(pdfBlob);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", `ECAI24-Paper-Registrations.xlsx`); // Set the desired filename for the downloaded file

      // Append the <a> element to the body and click it to trigger the download
      document.body.appendChild(tempLink);
      tempLink.click();

      // Clean up the temporary elements and URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };


  // Define the columns
  const columns = useMemo(() => [

    {
      accessorKey: "paper_id", // accessor is the "key" in the data
      header: "ID",
      size: 10,
    },
    {
      accessorKey: "title",
      header: "Title",
      size: 300,
    },
    {
      accessorKey: "author_emails",
      header: "Authors",
      size: 500,
      Cell: ({ cell }) => {
        const authors_json=JSON.parse(cell.getValue());
        if (authors_json) {
          return (
            <div className="flex flex-wrap">
              {authors_json.map((i,index) => (<div key={index} className="w-1/2">{i}</div>))}
            </div>);
        }
        else return (<div></div>)
      }
    },
    {
      accessorKey: "registrations",
      header: "Registrations",
      size: 50,
    },
    {
      accessorKey: "extra_pages",
      header: "Pages",
      size: 50,
    },
    {
      accessorKey: "state", //simple recommended way to define a column
      header: "State",
      muiTableHeadCellProps: { style: { color: "black" } }, //custom props
      enableHiding: false, //disable a feature for this column
      size: 50,
      // custom conditional format and styling
      // https://www.material-react-table.com/docs/examples/advanced
      Cell: ({ cell }) => {
        return (
          cell.getValue() && (
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor:
                  cell.getValue() === "Registered"
                    ? theme.palette.success.light
                    : theme.palette.error.light,
                borderRadius: "0.25rem",
                color: "#fff",
                p: "0.3rem",
              })}
            >
              {cell.getValue()}
            </Box>
          )
        );
      },
    },
  ]);

  const handleFilter = (event, column, filter) => {
    const selectedFiltersByColumn = event.target.checked
      ? { ...filters, [column]: [...(filters[column] || []), filter] }
      : { ...filters, [column]: filters[column].filter((f) => f !== filter) };

    // Remove column if its array is empty
    if (selectedFiltersByColumn[column].length === 0) {
      delete selectedFiltersByColumn[column];
    }

    setFilters(selectedFiltersByColumn);

    const filteredData = originalData.filter((item) =>
      Object.entries(selectedFiltersByColumn).every(([column, filterArray]) =>
        filterArray.includes(item[column])
      )
    );

    setData(filteredData);
  };

  //pass table options to useMaterialReactTable
  const table = useMaterialReactTable({
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    initialState: { density: "compact" },
    enableRowSelection: false, //enable some features
    enableColumnOrdering: true, //enable a feature for all columns
    enableGlobalFilter: true, //turn off a feature
    muiTableHeadCellProps: {
      // simple styling with the `sx` prop, works just like a style prop in this example
      // https://www.material-react-table.com/docs/guides/customize-components#the-sx-prop
      sx: {
        backgroundColor: "#e3f1ff",
      },
    },
  });
  return (
    <div className="flex flex-col v-full h-full ">
      <div className="flex justify-between flex px-8 py-4 absolute w-full top-0 left-0">
        <div className="flex flex-wrap justify-end w-full">
          <BadgesFilter
            key={useId()}
            index={useId()}
            title="Registered"
            bgColor="#1c9d00"
            handleFilter={(e) => handleFilter(e, "state", "Registered")}
          />
          <BadgesFilter
            key={useId()}
            index={useId()}
            title="Not registered"
            bgColor="#FF1300"
            handleFilter={(e) => handleFilter(e, "state", "Not registered")}
          />
        </div>
        <div
            className=" flex flex-end cursor-pointer ml-8"
            onClick={() => downLoadExcel()}
          >
            {" "}
            <RiFileExcel2Line className="text-green-800" /> Export
          </div>
      </div>

      <div>
        <MaterialReactTable table={table} className="h-full" />
      </div>
    </div>
  );
}



function Papers() {
  const [value, setValue] = useState('one');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = (props) => {
    if (value === props.value) {
      return (
        <div className="mt-4 p-2">
          {props.children}
        </div>
      );
    }
  }

  return (<ListPapers />);
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="paper tabs"
        >
          <Tab
            value="one"
            label="Papers"
          />
          <Tab value="two" label="Delegates" />

        </Tabs>
      </Box>
      <TabPanel value="one"><ListPapers /></TabPanel>
      <TabPanel value="two">a</TabPanel>
    </>
  );
}

export default Papers;