// Function to render an Ecai styled card
function EcaiCard(props) {
  const { variant, variant2, onClick, selected, size = "regular" } = props;

  return (
    <div
      className={`ecai-card 
      ${variant ? "variant" : ""} 
      ${variant2 ? "variant2" : ""} 
      ${size}`}
      onClick={onClick}
    >
      <div className={`${selected ? "selected" : ""}`}>{props.children}</div>
    </div>
  );
}

export default EcaiCard;
