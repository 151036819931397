import React, { useContext, useEffect, useState } from "react";
import workshops from "../../util/workshops.json";
import RegistrationContext from "../../contexts/RegistrationContext";
import axios from "axios";
import { base_url } from "../../config";
import "./style.scss";
import WhiteBoxLayout from "../../components/WhiteBoxLayout/WhiteBoxLayout";
import EcaiCard from "../../components/EcaiCard/EcaiCard";
import scheduleTransform from "../../util/scheduleTransform";

function SearchWorkshopTutorials(props) {
  const { handleChange } = props;

  return (
    <div className={props.className}>
      <form className="max-w-full lg:max-w-md mx-auto lg:ml-auto lg:mr-0">
        <label
          htmlFor="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
        >
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              aria-hidden="true"
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 19l-4-4m0-7A7 7 0 111 8a7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-full p-2.5 ps-10 text-sm text-gray-900 border border-gray-300 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Search"
            onChange={handleChange}
          />
        </div>
      </form>
    </div>
  );
}

function WorkshopSelection() {
  const [filteredWorkshops, setFilteredWorkshops] = useState(workshops);
  const { setError, reload, setReload, data, clickedNext, setClickedNext } =
    useContext(RegistrationContext);
  const [selectedOptions, setSelectedOptions] = useState(
    workshops.filter((workshop) => {
      return data.length > 0 && data.includes(workshop.id);
    })
  );

  const toggleOption = (option) => {
    const isSelected = selectedOptions.some(
      (selectedOption) => selectedOption.id === option.id
    );

    const schedule = scheduleTransform(option.schedule);
    const totalSaturdayWorkshops = selectedOptions
      .map((selectedOption) => scheduleTransform(selectedOption.schedule))
      .filter((schedule) => schedule.saturday);
    const totalSundayWorkshops = selectedOptions
      .map((selectedOption) => scheduleTransform(selectedOption.schedule))
      .filter((schedule) => schedule.sunday);

    if (
      (!isSelected &&
        schedule.saturday &&
        totalSaturdayWorkshops.length >= 3) ||
      (!isSelected && schedule.sunday && totalSundayWorkshops.length >= 3)
    ) {
      setError("You can only select up to 3 workshops/tutorials/DC per day.");
      return;
    }

    if (isSelected) {
      setSelectedOptions(
        selectedOptions.filter(
          (selectedOption) => selectedOption.id !== option.id
        )
      );
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleSearchChange = (event) => {
    const searchInput = event.target.value.toLowerCase();
    const filtered = workshops.filter(
      (workshop) =>
        workshop.name.toLowerCase().includes(searchInput) ||
        workshop.type.toLowerCase().includes(searchInput)
    );
    setFilteredWorkshops(filtered);
  };

  const handleSelect = async () => {
    // Reset login error state

    setError("");
    try {
      // Make a POST request to your authentication endpoint
      const response = await axios.post(`${base_url}/api/progress`, {
        workshop_selection: selectedOptions.map((option) => option.id),
      });
      setReload(reload + 1);
    } catch (error) {
      setError("Unexpected error. " + error.response.data.message);
    }
  };

  useEffect(() => {
    if (clickedNext === 0) return;
    setClickedNext(0);
    handleSelect();
  }, [clickedNext]);

  const totalSaturdayWorkshops = selectedOptions
    .map((option) => scheduleTransform(option.schedule))
    .filter((schedule) => schedule.saturday);

  const totalSundayWorkshops = selectedOptions
    .map((option) => scheduleTransform(option.schedule))
    .filter((schedule) => schedule.sunday);

  return (
    <WhiteBoxLayout title="Workshop Selection">
      <div className="workshop-selection overflow-x-hidden">
        <div className="flex w-full flex-col xl:flex-row">
          <div className="w-full xl:w-2/4 my-8 xl:my-0">
            <div className="pb-4 text-sm font-medium text-gray-900">
              Your selection (please, select only those that you are going to
              attend):
            </div>
            <div className="flex gap-2 items-end">
              <div
                className={`selection-card w-1/2 md:w-auto ${
                  totalSaturdayWorkshops.length > 0 ? "active" : ""
                }`}
              >
                Saturday
                <br />
                {totalSaturdayWorkshops.length} Workshop
                {totalSaturdayWorkshops.length > 1 ? "s" : ""} / Tutorial
                {totalSaturdayWorkshops.length > 1 ? "s" : ""} / DC
              </div>
              <div
                className={`selection-card w-1/2 md:w-auto  ${
                  totalSundayWorkshops.length > 0 ? "active" : ""
                }`}
              >
                Sunday
                <br />
                {totalSundayWorkshops.length} Workshop
                {totalSundayWorkshops.length > 1 ? "s" : ""} / Tutorial
                {totalSundayWorkshops.length > 1 ? "s" : ""} / DC
              </div>
            </div>
          </div>
          <div className="w-full xl:w-2/4 flex justify-between items-end gap-2 flex-wrap sm:flex-nowrap">
            {/* Filter select dropdown */}
            <div className="w-5/12 sm:w-48">
              <label
                htmlFor="event-type"
                className="block mb-2 mr-2 text-sm font-medium text-gray-900 w-40"
              >
                Filter by type:
              </label>
              <select
                id="event-type"
                name="event-type"
                onChange={(event) => {
                  const searchInput = event.target.value.toLowerCase();
                  const filtered = workshops.filter((workshop) =>
                    workshop.type.toLowerCase().includes(searchInput)
                  );
                  setFilteredWorkshops(filtered);
                }}
                defaultValue=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
              >
                <option value="">All</option>
                <option value="Workshop">Workshop</option>
                <option value="Tutorial">Tutorial</option>
                <option value="DC">DC</option>
              </select>
            </div>

            <div className="w-5/12 sm:w-48">
              <label
                htmlFor="event-type"
                className="block mb-2 mr-2 text-sm font-medium text-gray-900 w-56"
              >
                Filter by selection:
              </label>
              <select
                id="event-selected"
                name="event-selected"
                onChange={(event) => {
                  const searchInput = event.target.value.toLowerCase();
                  setFilteredWorkshops(
                    searchInput === "selected" ? selectedOptions : workshops
                  );
                }}
                defaultValue=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
              >
                <option value="">All</option>
                <option value="selected">Selected</option>
              </select>
            </div>

            <SearchWorkshopTutorials
              handleChange={handleSearchChange}
              className="w-full"
            />
          </div>
        </div>
        <div className="py-6 space-y-4 md:space-y-6">
          <div className="mx-auto">
            <div style={{ maxWidth: "100%", justifyContent: "center" }}>
              <div style={{ display: "inline-block" }}>
                <div className="font-bold grid w-full h-full">
                  {filteredWorkshops.map((workshop, index) => {
                    const isSelected = selectedOptions
                      .map((a) => a.id)
                      .includes(workshop.id);
                    const schedule = scheduleTransform(workshop.schedule);

                    return (
                      <div className="relative">
                        <EcaiCard
                          key={`card-${index}`}
                          variant={workshop.type === "Tutorial"}
                          variant2={workshop.type === "DC"}
                          onClick={() => toggleOption(workshop)}
                          selected={isSelected}
                        >
                          <div className="h-full w-full flex justify-between flex-col">
                            <div>
                              <h3 className="text-gray-400 text-sm mb-2">
                                {workshop.type}
                              </h3>
                              <div className="font-normal text-sm">
                                Schedule: {schedule.text}
                              </div>
                            </div>

                            <div>
                              <div>{workshop.name}</div>
                            </div>
                          </div>
                        </EcaiCard>
                        <div className="workshop-chip">
                          <a
                            href={workshop.web}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Site
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WhiteBoxLayout>
  );
}

export default WorkshopSelection;
