import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { base_url } from "../../config";
import RegistrationContext from "../../contexts/RegistrationContext";
import { Trash2 } from "lucide-react";
import { FileText } from "lucide-react";
import WhiteBoxLayout from "../../components/WhiteBoxLayout/WhiteBoxLayout";
import "./style.scss";
import AddToCart from "../../components/AddToCart/AddToCart";
import AlertDialog from "../../components/AlertDialog/AlertDialog";

function Papers() {
  const {
    setError,
    reload,
    setReload,
    data,
    paperSelected,
    setPaperSelected,
    clickedNext,
    setClickedNext,
    setStatus,
    setCart,
    setHighlightedCart,
    extraPages,
    setExtraPages,
  } = useContext(RegistrationContext);
  // const [extraPages, setExtraPages] = useState(
  //   data.extra_pages ? data.extra_pages : {}
  // );
  const [buttonsEnabled, setButtonsEnabled] = useState({});
  const [addPaperId, setAddPaperId] = useState();
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const papersButton = true;

  const handleSubmit = async () => {
    setError("");
    if (addPaperId) {
      setShowAlertDialog(true);
      return;
    }
    try {
      // Make a POST request to your authentication endpoint
      await axios.post(`${base_url}/api/progress`, {});
      setReload(reload + 1);
    } catch (error) {
      setError("Unexpected error. " + error.response.data.message);
    }
  };
  const handleAddItem = async (item, params) => {
    try {
      // Make a POST request to your authentication endpoint
      const response = await axios.post(`${base_url}/api/add_item`, {
        item,
        ...params,
      });

      setStatus(
        "Item added to cart.\nTo review or remove items from your basket, please click the basket icon in the top right corner."
      );
      setHighlightedCart(true);
      setCart(response.data.cart);
    } catch (error) {
      setError("Unexpected error. " + error.response.data.message);
    }
  };

  const handleRemoveItem = async (item, item_id) => {
    try {
      // Make a POST request to your authentication endpoint
      const response = await axios.post(`${base_url}/api/remove_item`, {
        item,
        item_id,
      });

      setHighlightedCart(true);
      setCart(response.data.cart);
    } catch (error) {
      setError("Unexpected error. " + error.response.data.message);
    }
  };

  useEffect(() => {
    if (clickedNext === 0) return;
    setClickedNext(0);
    handleSubmit();
  }, [clickedNext]);

  // Callback function to remove a paper from the paperSelected array
  const handleRemovePaper = (index) => {
    handleRemoveItem(paperSelected[index], "papers");
    setPaperSelected(
      paperSelected.filter((paper, currentIndex) => currentIndex !== index)
    );
  };

  const handleChange = (paperId) => {
    const regex = /^[MPDJ]\d\d?\d?\d?$/;

    let error_code = 0;
    if (!regex.test(paperId) || !paperId) error_code = 1;
    if (!error_code && paperSelected.includes(paperId)) error_code = 2;

    switch (error_code) {
      case 0: // No error
        setPaperSelected([...paperSelected, paperId]);
        handleAddItem("papers", { item_id: paperId });
        break;
      case 1:
        setError(
          "The registered paper code must be an uppercase letter (M, P, D or J) followed by the paper ID ."
        );
        break;
      case 2:
        setError("Paper " + paperId + " already registered");
        break;
    }
  };

  const ExtraPagesLine = ({ paper, index }) => {
    const changePages = (e) => {
      setExtraPages({ ...extraPages, [paper]: e.target.value });
      setButtonsEnabled({ ...buttonsEnabled, [paper]: true });
    };

    const selectPages = () => {
      handleAddItem("pages", {
        item_id: extraPages[paper],
        paper_id: paper,
      });
      setButtonsEnabled({ ...buttonsEnabled, [paper]: false });
    };
    return (
      <div
        className={`w-full flex items-center flex-wrap lg:flex-nowrap`}
        key={index}
      >
        <div className="text-sm w-full sm:w-40 ml-10">
          <label htmlFor={`extra_pages_${index}`}>
            Extra pages for {paper}?
          </label>
        </div>
        <div className="mr-2">
          <div>
            <select
              id={`extra_pages_${index}`}
              name={`extra_pages_${index}`}
              defaultValue={extraPages[paper]}
              className={"pages_select"}
              onChange={(e) => changePages(e)}
              required
            >
              <option value="0">None</option>
              <option value="1">1 Page</option>
              <option value="2">2 Pages</option>
            </select>
          </div>
        </div>
        <div>
          <AddToCart
            onClickHanlder={() => selectPages()}
            disabled={!buttonsEnabled[paper]}
            text="Update cart"
          />
        </div>
      </div>
    );
  };

  const ExtraPages = () => {
    if (!paperSelected || paperSelected.length === 0) return null;
    return (
      <div className={`flex flex-col`}>
        <div className="text-sm mb-4">
          Purchase extra pages (€250 each page):
        </div>
        {paperSelected.map((paper, index) => (
          <ExtraPagesLine paper={paper} index={index} />
        ))}
      </div>
    );
  };

  return (
    <WhiteBoxLayout title="Papers">
      {showAlertDialog && <AlertDialog setOpen={setShowAlertDialog}>
            Your paper selection has not been saved. Please go back and click
            <span className="font-bold"> Add Paper to Cart </span>to add the
            paper <span className="font-bold">{addPaperId}</span> to your
            registration.
        </AlertDialog>}
      <div className="space-y-4 md:space-y-6 ">
        <form
          className="space-y-4 md:space-y-6"
          method="POST"
          onSubmit={handleSubmit}
        >
          <div className="px-2">
            <div className=" flex md:gap-8 " style={{ display: "block" }}>
              <div className="w-full mt-1">
                <div className="py-2 font-bold leading-tight tracking-tight text-gray-900 text-xl flex gap-2 border-b-slate-200 border-b mb-2">
                  <FileText /> Your papers
                </div>

                <div className="text-sm mb-6">
                  <p className="font-bold mt-4 mb-2">Paper Registration:</p>
                  <ul className="list-outside ml-5">
                  <li className="list-disc">
                    Workshop papers don't need to be declared here. This is only required for main conference papers.
                  </li>
                    <li className="list-disc">
                      You may add up to five papers to your registration.
                    </li>
                    <li className="list-disc">
                      The first two papers are included in the registration fee;
                      additional papers incur a fee.
                    </li>
                    <li className="list-disc">
                      You must be an author of each paper submitted.{" "}
                      <b className=" bg-yellow-100">
                        {" "}
                        Authorship will be verified.{" "}
                      </b>
                    </li>
                    <li className="list-disc">
                      You can purchase extra pages (Max 2 extra pages per
                      paper), €250 each page
                    </li>
                  </ul>
                </div>

                <div className="text-sm mb-6">
                  <p className="font-bold mt-4 mb-2">Important:</p>
                  <p className="mb-2">
                  The registered paper code must be an uppercase letter
                    followed by up to four digits number like: M1234. <br /><br />
                    Letters are one of the following:
                  </p>
                  <ul className="list-outside ml-5">
                    <li className="list-disc">M - Maintrack</li>
                    <li className="list-disc">P - PAIS</li>
                    <li className="list-disc">D - Demo</li>
                    <li className="list-disc">J - Journal track</li>
                  </ul>
                </div>

                <div className="flex text-xs">
                  <div className="w-full">
                    <div className="flex flex-col sm:flex-row sm:gap-10 md:gap-32 mb-4">
                      <div className="w-full flex flex-col lg:flex-row mt-2 items-start lg:items-center">
                        <div className="w-36">
                          <label
                            htmlFor="paper-registered"
                            className="block mb-2 text-sm font-medium text-gray-900"
                          >
                            Registered paper:{" "}
                          </label>
                        </div>
                        <div className="flex gap-2 flex-col sm:flex-row sm:flex-wrap">
                          {paperSelected &&
                            paperSelected.length > 0 &&
                            paperSelected.map(
                              (
                                paper,
                                index // SELECTED PAPERS LIST
                              ) => (
                                <div className="flex gap-2 mb-2 ">
                                  <div className="relative">
                                    {index >= 2 && (
                                      <div className="freePaper">€200</div>
                                    )}
                                    <input
                                      type="text"
                                      name="paperInput"
                                      value={paper}
                                      className="w-40 lg:w-24 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block p-2.5"
                                      disabled
                                    />
                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-500 hover:text-red-600">
                                      <Trash2
                                        onClick={() => handleRemovePaper(index)}
                                        size="16"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          {paperSelected.length < 5 && ( // ADD PAPER
                            <div className="flex gap-2 mb-2 ">
                              <div className="relative">
                                {paperSelected.length >= 2 && (
                                  <div className="freePaper">€200</div>
                                )}
                                <input
                                  type="text"
                                  name="paperInput"
                                  value={addPaperId}
                                  onChange={(e) => {
                                    setAddPaperId(e.target.value);
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault();
                                      setAddPaperId("");
                                      handleChange(addPaperId);
                                    }
                                  }}
                                  className="w-40 lg:w-24 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block p-2.5"
                                />
                              </div>

                              <div className="p-2">
                                <AddToCart
                                  onClickHanlder={() => {
                                    setAddPaperId("");
                                    handleChange(addPaperId);
                                  }}
                                  papersButton={papersButton}
                                  disabled={
                                    addPaperId ? addPaperId === "" : true
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <ExtraPages />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </WhiteBoxLayout>
  );
}

export default Papers;
