import React, { useContext, useEffect, useRef, useState } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import "./style.scss";
import { User } from "lucide-react";
import axios from "axios";
import { base_url } from "../../config";

function UserInfo() {
  const {
    reload,
    setStatus,
    setUserInfoOpen,
    setReload,
    setError,
    setEmail,
    email,
  } = useContext(RegistrationContext);

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordError2, setPasswordError2] = useState(false);

  useEffect(() => {
    setPasswordError(false);
  }, []);
  const handleChangePassword = async () => {
    setError("");

    if (password !== password2) {
      setPasswordError(true);
      setPasswordError2(false);
      return;
    } else if (password.length < 8) {
      setPasswordError2(true);
      setPasswordError(false);
      return;
    }

    try {
      // Make a POST request to your authentication endpoint
      const response = await axios.post(`${base_url}/api/change_password`, {
        password: password,
      });
      setStatus("Password Changed");
      setReload(reload + 1);
      setUserInfoOpen(false);
    } catch (error) {
      setError("Unexpected error. " + error.response.data.message);
    }
  };

  return (
    <div className="resetForm user-input-container flex flex-col justify-center w-full max-w-full text-left lg:px-6 md:py-8 sm:mx-auto lg:w-auto md:h-full md:py-0">
      <div className="flex gap-4">
        <div style={{ color: "#20334d" }}>
          <User size={30} />
        </div>
        <div
          className="sectionTitle"
          style={{ color: "#20334d", marginBottom: "50px" }}
        >
          User Information
        </div>
      </div>
      <div className="w-full bg-white shadow md:mt-0 lg:max-w-md xl:p-0  ">
        <div className="w-full p-6 space-y-4 md:space-y-6 sm:p-8">
          <form className="space-y-4 md:space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Your email
              </label>
              <input
                type="text"
                name="email"
                id="email"
                className="input bg-gray-50 border border-sky-950 text-gray-400 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block  p-2.5      "
                placeholder="name@company.com"
                value={email}
                disabled={true}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <h1 className="text-lg font-bold leading-tight tracking-tight text-gray-900 ">
              Change your password
            </h1>
            <div>
              <label
                html="password"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                New password
              </label>
              <input
                //value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                className="input mt-0 bg-gray-50 border border-sky-950 text-gray-900 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block p-2.5      "
              />
            </div>
            <div
              style={{ marginTop: "0px" }}
              className={passwordError2 ? "text-red-700" : ""}
            >
              {passwordError2 ? (
                "The password must contain at least 8 characters"
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
            <div>
              <label
                html="password"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Confirm password
              </label>
              <input
                //value={password}
                onChange={(e) => setPassword2(e.target.value)}
                required
                type="password"
                name="password2"
                id="password2"
                placeholder="••••••••"
                className="input bg-gray-50 border border-sky-950 text-gray-900 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block  p-2.5      "
              />
            </div>
            <div
              style={{ marginTop: "0px" }}
              className={passwordError ? "text-red-700" : ""}
            >
              {passwordError ? "Passwords does not match" : <span>&nbsp;</span>}
            </div>
            <div className="flex items-center justify-between">
              <div className="button" onClick={() => handleChangePassword()}>
                Reset
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UserInfo;
