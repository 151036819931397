import React from "react";
import "./Loading.scss"; // Import the CSS file

const Loading = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <img
        className="fadeIn"
        src="../../../../images/logo-ecai.svg"
        width="290"
        height="84"
        alt="ECAI-LOGO"
        id="ecmlLogo"
      />
      <div className="fadeIn">REGISTRATION</div>
    </div>
  );
};

export default Loading;
