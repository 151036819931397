import React, { useContext } from "react";
import axios from "axios";
import RegistrationContext from "../../contexts/RegistrationContext";
import { base_url, steps } from "../../config";
import getStepValue from "../../util/getStepValue";
import "./style.scss";
import { ChevronRight } from "lucide-react";

function Stepper() {
  const {
    setReload,
    reload,
    setError,
    screen,
    maxStep,
    isMainAccess,
    clickedNext,
    setClickedNext,
    optionAccess,
    isStudent,
  } = useContext(RegistrationContext);
  const handler = async (step) => {
    setError("");
    try {
      await axios.post(`${base_url}/api/jump`, {
        step: step,
      });
      setReload(reload + 1);
    } catch (error) {
      setError("Not accessible step");
    }
  };
  return (
    <div className="stepper_container">
      <div className="stepper_box flex items-center flex-col lg:flex-row">
        <div className="menu-logo self-center lg:self-start mb-4 xl:mb-0 xl:mr-8">
          <a href="http://ecai2024.eu" target="_blank" rel="noreferrer">
            <img
              src="../../../../images/logo-ecai.svg"
              alt="ECAI-LOGO"
              id="ECAILogo"
              width="150"
              height="78"
            />
          </a>
        </div>
        <div className="menu flex flex-wrap justify-center lg:justify-end items-center w-full text-sm md:text-base flex-row">
          {steps.map((step, index) => {
            let isGray = false;
            let isHidden = false;

            if (screen === "Confirmation") {
              isGray = step !== "Confirmation";
            } else {
              isGray = getStepValue(step) > getStepValue(maxStep);
            }

            if (isMainAccess && step === "Workshop Selection") {
              isHidden = true;
            }
            if (optionAccess === "workshops" && step === "Extras") {
              isHidden = true;
            }
            if (isStudent && step === "Papers") {
              isHidden = true;
            }
            if (optionAccess === "workshops" && step === "Papers") {
              isHidden = true;
            }
            const isBold = step === screen;
            const isLast = index !== steps.length - 1;

            if (isHidden) {
              return null;
            }
            return (
              <div
                key={index}
                className={
                  "flex items-center " +
                  (isBold ? "font-bold  " : "") +
                  (isGray ? "text-stone-400 " : "cursor-pointer")
                }
                onClick={() => (isGray ? null : handler(step))}
              >
                <div className="gap-2">
                  {step === "Workshop Selection" ? "Weekend Selection" : step}
                </div>
                {isLast && (
                  <div className="block chevron-right-wrapper">
                    <ChevronRight />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {screen !== "Payment" && (
        <div className="next-form w-full flex justify-center lg:justify-end my-1">
          <button
            className="button next-button"
            onClick={() => setClickedNext(clickedNext + 1)}
          >
            Next <ChevronRight />
          </button>
        </div>
      )}
    </div>
  );
}

export default Stepper;
