import React, { useContext } from "react";
import "./style.scss";
import workshops from "../../util/workshops.json";

import { useEffect } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import { base_url } from "../../config";

async function downloadFile(fileUrl, fileName) {
  const token = localStorage.getItem("token");
  const response = await fetch(fileUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();

  // The timeout is necessary in some browsers to revoke the object URL after creating the download
  setTimeout(() => window.URL.revokeObjectURL(url), 100);
}


function Summary() {
  const { data } = useContext(RegistrationContext);

  useEffect(() => {
    window.history.replaceState({}, "", "/");
  }, []);
  const selected_workshops_list = [];

  workshops.forEach((workshop) => {
    if (
      data.workshop_selection &&
      data.workshop_selection.includes(workshop.id)
    ) {
      selected_workshops_list.push(workshop);
    }
  });

  return (
    <div className="flex flex-col h-full grow">
      <div
        style={{ maxWidth: "1152px", width: "100%" }}
        className="flex justify-start flex-col h-full items-center mx-auto lg:py-0 text-left"
      >
        <div
          style={{ maxWidth: "1152px", width: "100%", maxHeight: "100%" }}
          className="bg-white md:mt-0 sm:max-w-md xl:p-0  "
        >
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
            <h1 className="text-center px-10 pb-4 text-2xl">
              INVITATION LETTER
            </h1>

            <div className="summary-card">
              <p>Download an invitation letter for the conference. <br />
                This letter is designed to assist you with your administrative processes to attend the conference. <br /><br />
             
              </p>
              <div className="flex  mt-8 mb-1 flex-col sm:flex-row justify-end">
                <div className="button rounded-sm" onClick={() => downloadFile(`${base_url}/api/get_invitation_letter`, 'invitation_letter.pdf')}>
                  Get invitation letter</div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
