
import React from 'react';

export default function App(props) {

    return (
        <div className='h-full flex flex-col relative'>
            <div className="px-8 py-2">
                <h1 className="flex gap-4 text-xl items-center">{props.icon} {props.title}</h1>
            </div>
            <div className="p-8 grow">
                {props.children}
            </div>
        </div>
    );
}






