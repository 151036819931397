import React, { useEffect, useState } from "react";
import axios from "axios";
import { useContext } from "react";
import RegistrationContext from "../contexts/RegistrationContext";
import { base_url } from "../config";

function Signin() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    code: "",
  });
  const [passwordError, setPasswordError] = useState(false);
  const [passwordError2, setPasswordError2] = useState(false);

  const { setScreen, setAuthenticated, setError, setReload, reload } =
    useContext(RegistrationContext);
  const [codeSent, setCodeSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setPasswordError(true);
      setPasswordError2(false);
      return;
    } else if (formData.password.length < 8) {
      setPasswordError2(true);
      setPasswordError(false);
      return;
    }
    setPasswordError(false);
    setPasswordError2(false);
    try {
      await axios.post(`${base_url}/api/register`, formData);
      setError("");
      setCodeSent(true);
    } catch (error) {
      setError("Registration failed. " + error.response.data.message);
    }
  };

  const handleSubmitCode = async (event) => {
    event.preventDefault();

    try {
      // Adjust URL to your API endpoint for registration
      const response = await axios.post(`${base_url}/api/register-code`, {
        code: formData.code,
        email: formData.email,
      });
      setAuthenticated(true);
      localStorage.setItem("token", response.data.access_token);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.access_token}`;
      setReload(reload + 1);
    } catch (error) {
      setError("Registration failed." + error.response.data.message);
    }
  };
  useEffect(() => {
    setPasswordError(false);
  }, []);
  return (
    <>
      {!codeSent && (
        <div class="flex flex-row items-start justify-start py-8 mx-auto md:h-full lg:py-0 text-left">
          <div class="w-full bg-white md:mt-0 sm:max-w-md xl:p-0  ">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                Create an account
              </h1>
              <form class="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="email"
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Email:
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                    placeholder="name@company.com"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Password:
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="••••••••"
                    value={formData.password}
                    onChange={handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                    required
                  />
                </div>
                <div
                  style={{ marginTop: "0px" }}
                  className={passwordError2 ? "text-red-700" : ""}
                >
                  {passwordError2 ? (
                    "The password must be at least 8 characters"
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="confirmPassword"
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Confirm Password:
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    placeholder="••••••••"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                    required
                  />
                </div>
                <div
                  style={{ marginTop: "0px" }}
                  className={passwordError ? "text-red-700" : ""}
                >
                  {passwordError ? "Passwords do not match" : <span></span>}
                </div>
                <button className="button" type="submit">
                  Register
                </button>
              </form>
            </div>
          </div>
          <div className="flex flex-col px-6 py-8 mx-auto text-right">
            <button
              className="hover:underline"
              onClick={() => setScreen("login")}
            >
              {`<<`} Back
            </button>
          </div>
        </div>
      )}
      {codeSent && (
        <div className="flex flex-col items-start mx-auto md:h-full lg:py-0 text-left">
          <div className="w-full bg-white rounded-lg md:mt-0 sm:max-w-md xl:p-0">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                Enter verification code
              </h1>
              <form
                className="space-y-4 md:space-y-6"
                onSubmit={handleSubmitCode}
              >
                <div>
                  <label
                    htmlFor="code"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Verification Code:
                  </label>
                  <input
                    type="text"
                    id="code"
                    name="code"
                    value={formData.code}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    required
                  />
                </div>
                <button className="button" type="submit">
                  Verify
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Signin;
