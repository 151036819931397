import React, { useCallback, useContext, useEffect, useState } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import axios from "axios";
import { base_url } from "../../config";
import "./style.scss";
import WhiteBoxLayout from "../../components/WhiteBoxLayout/WhiteBoxLayout";
import EcaiCard from "../../components/EcaiCard/EcaiCard";
import FileUpload from "../../components/FileUpload/FileUpload";
import { Tooltip } from "@mui/material";

// Function to render a registration option block
function RegistrationOption(props) {
  const { registration, optionChecked, handleChange } = props;

  return (
    <div className="mt-4">
      <div className="pt-2 pl-2 pr-2 px-8">
        <h2 className="text-2xl">{registration.title}</h2>
      </div>

      <div className="flex py-6 gap-6 lg:gap-2 flex-col md:flex-row justify-center lg:justify-start">
        {/* Iteration through registration option blocks ecai card style related to radio buttons */}
        {registration.options.map((option, index) => {
          return (
            <div
              className="flex flex-col self-center md:self-start"
              key={`card-${index}`}
            >
              <EcaiCard
                title={option.value}
                variant={registration.variant}
                onClick={(e) => {
                  return handleChange(option.value);
                }}
                selected={optionChecked === option.value}
                size="big"
              >
                <div className="h-full w-full flex justify-between flex-col">
                  <div className="text-gray-700 text-sm">
                    {option.description}
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      id={option.value}
                      type="radio"
                      name={registration.name}
                      value={option.value}
                      checked={optionChecked === option.value}
                      onChange={(e) => handleChange(e.target.value)}
                      className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500"
                      required
                    />
                    <div className="text-sm font-bold">{option.label}</div>
                  </div>
                </div>
              </EcaiCard>
            </div>
          );
        })}
      </div>
    </div>
  );
}

// Main component function
function RegistrationType() {
  const {
    reload,
    phase,
    inCommunication,
    setError,
    setInCommunication,
    setReload,
    data,
    clickedNext,
    setClickedNext,
    prices,
  } = useContext(RegistrationContext);
  // useState hooks
  const [regType, setRegType] = useState(data.type ?? "regular");
  const [regOption, setRegOption] = useState(data.option ?? "full");
  const [ecaiMemberCheck, setEcaiMemberCheck] = useState(
    data.ecaiMemberCheck == 1
  );
  const [fileEcai, setFileEcai] = useState(null);
  const [fileStudent, setFileStudent] = useState(null);
  const [backendFileStudent, setBackendFileStudent] = useState(
    data.studentProofFile
  );
  const [backendFileEcai, setBackendFileEcai] = useState(data.euraiProofFile);

  const initialValues = {
    regType: data.type ?? "regular",
    regOption: data.option ?? "full",
    ecaiMemberCheck: data.ecaiMemberCheck ?? false,
    fileEcai: data.euraiProofFile,
    fileStudent: data.studentProofFile,
  };


  const handleSelect = useCallback(async () => {
    if (inCommunication) return;
    setInCommunication(true);
    // Reset login error state
    setError("");

    if (regType === "student" && !fileStudent && !backendFileStudent) {
      setError("Student proof is required for student registration.");
      setInCommunication(false);
      return;
    }
    if (ecaiMemberCheck && !fileEcai && !backendFileEcai) {
      setError("Eurai member proof is required if you are a member.");
      setInCommunication(false);
      return;
    }

    const formData = new FormData();
    formData.append("type", regType);
    formData.append("option", regOption);
    formData.append("euraiMember", ecaiMemberCheck);
    formData.append("studentProofFile", fileStudent);
    formData.append("euraiProofFile", fileEcai);


    try {
      // Make a POST request to your authentication endpoint
      await axios.post(`${base_url}/api/progress`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setReload(reload + 1);
    } catch (error) {
      setError("Login failed. " + error.response.data.message);
    }
    setInCommunication(false);
  }, [
    ecaiMemberCheck,
    fileEcai,
    fileStudent,
    inCommunication,
    regOption,
    regType,
    reload,
    setError,
    setInCommunication,
    setReload,
  ]);
  const setClickedNext0 = useCallback(
    () => setClickedNext(0),
    [setClickedNext]
  );

  useEffect(() => {
    if (clickedNext === 0) return;
    setClickedNext0();
    handleSelect();
  }, [clickedNext, handleSelect, setClickedNext0]);

  const registration = {
    type: {
      title: "Delegate type",
      name: "type",
      options: [
        {
          label: "Regular Registration",
          value: "regular",
          description: (
            <ul className="list-disc list-outside mt-2 ml-4 mr-2">
              <li>Elegible to all delegates</li>
            </ul>
          ),
        },
        {
          label: "Student Registration",
          value: "student",
          description: (
            <ul className="list-disc list-outside mt-2 ml-4 mr-2">
              <li className="font-bold">Cannot register papers</li>
              <li>Reduced registration fees</li>
              <li>Student proof required</li>
            </ul>
          ),
        },
      ],
      variant: false,
    },
    option: {
      title: "Conference access",
      name: "option",
      options: [
        {
          label: "Full conference",
          value: "full",
          description: (
            <>
            <div>Access to:</div>
            <ul className="list-disc list-outside mt-2 ml-4 mr-2">
              <li>All main conference sessions</li>
              <li>Tutorials, workshops and DC</li>
              <li>Conference reception* </li>
            </ul>
            <div className="text-xs mt-4">* first come first service until sold out</div>
            
            </>
          ),
        },
        {
          label: "Only Weekend",
          value: "workshops",
          description: (
            <>
            <div>Access to:</div>
            <ul className="list-disc list-outside mt-2 ml-4 mr-2">
              <li>Tutorials, workshops and DC</li>
            </ul>
            </>
          ),
        },
        {
          label: "Only Main Conference",
          value: "main",
          description: (
            <>
            <div>Access to:</div>
            <ul className="list-disc list-outside mt-2 ml-4 mr-2">
              <li>All main conference sessions</li>
              <li>Conference reception* </li>
            </ul>
            <div className="text-xs mt-4">* first come first service until sold out</div>
            
            </>
          ),
        },
      ],
      variant: true,
    },
  };

  const studentProofInfo =
    "Please upload an official student proof, such as an University enrollment, University ID card (with validity dates), Signed letter from your advisor in official paper.";
  const euraiMembershipInfo =
    "Ask your local association for a membership certificate and upload it here.";

  // Async function to send data to backend endpoint

  const getPrice = () => {
    const f = prices.filter(function (price) {
      return (
        price.regType === regType &&
        price.regOption === regOption &&
        price.eurai_member === ecaiMemberCheck
      );
    });
    if (f.length > 0) {
      return f[0].price;
    }
    return 0;
  };

  return (
    <WhiteBoxLayout gray={true}>
      
      <div className="registration-type lg:max-w-screen-md xl:max-w-full lg:mx-auto xl:">
        <div className="flex flex-col xl:flex-row xl:justify-between w-full">
          <RegistrationOption
            registration={registration.type}
            handleChange={setRegType}
            optionChecked={regType}
            phase={phase}
          ></RegistrationOption>

          <RegistrationOption
            registration={registration.option}
            handleChange={setRegOption}
            optionChecked={regOption}
            phase={phase}
          ></RegistrationOption>
        </div>

        {regType === "student" && (
          <div>
            <fieldset className="border rounded-3 p-3 mb-5">
              <legend className="float-none w-auto px-3">Student</legend>

              <FileUpload
                id="fileStudent"
                fileSource={fileStudent}
                handleUploadFile={(file) => setFileStudent(file)}
                handleRemoveFile={() => setFileStudent(null)}
                backendFile={backendFileStudent}
                backendFilePath={base_url + "/api/get_student_proof"}
                backendFileHandler={(file) => setBackendFileStudent(file)}
                onError={(message) => setError(message)}
              >
                <div className="mt-4 mb-4 md:mb-2 text-sm flex gap-2">
                  Please upload a proof of Student:
                  <span className="text-red-600">*</span>
                  <Tooltip title={studentProofInfo} placement="top-start">
                    <div className="chip">i</div>
                  </Tooltip>
                </div>
              </FileUpload>
            </fieldset>
          </div>
        )}
        <div>
          <fieldset className="border rounded-3 p-3 mb-5">
            <legend className="float-none w-auto px-3">Eurai member</legend>

            <div className="py-2 flex flex-col">
              <div className="mb-2">
                <input
                  id="ecaiMemberCheck"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500"
                  onClick={() => setEcaiMemberCheck(!ecaiMemberCheck)}
                  checked={ecaiMemberCheck}
                />
                <label
                  htmlFor="ecaiMemberCheck"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  I'm a EurAI member
                </label>
              </div>

              {ecaiMemberCheck && (
                <>
                  <FileUpload
                    id="fileEcai"
                    fileSource={fileEcai}
                    handleUploadFile={(e) => setFileEcai(e)}
                    handleRemoveFile={() => setFileEcai(null)}
                    backendFile={backendFileEcai}
                    backendFilePath={base_url + "/api/get_ecai_member"}
                    backendFileHandler={(file) => setBackendFileEcai(file)}
                    onError={(message) => setError(message)}
                  >
                    <p className="text-sm">
                      To qualify as a EurAI member you are required to provide a
                      valid certificate provided by your EurAI member society (
                      <a
                        href="https://eurai.org/members"
                        target="_blank"
                        className="underline"
                        style={{ color: "#006cff" }}
                        rel="noreferrer"
                      >
                        https://eurai.org/members
                      </a>
                      ).
                    </p>
                    <div className="mt-4 mb-4 md:mb-2 text-sm flex gap-2">
                      Please upload a proof of Eurai membership:
                      <span className="text-red-600">*</span>
                      <Tooltip
                        title={euraiMembershipInfo}
                        placement="top-start"
                      >
                        <div className="chip">i</div>
                      </Tooltip>
                    </div>
                  </FileUpload>
                </>
              )}
            </div>
          </fieldset>
        </div>
        <div className="flex justify-end mb-4">
          <div className="text-md font-bold">
            {/* TOTAL: {total[0].price_early}€ */}
            TOTAL: €{getPrice()}
          </div>
        </div>
      </div>
    </WhiteBoxLayout>
  );
}

export default RegistrationType;
