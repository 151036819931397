import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import RegistrationContext from "../../contexts/RegistrationContext";
import axios from "axios";
import { base_url, stripe_key } from "../../config";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";


const stripePromise = loadStripe(stripe_key);

const Opt = ({ description, price, varname,handler }) => (
  <div className="flex my-2">
    <div className="w-2/3 text-right mr-2">
      <label for={varname}>
        {description}: <b>€{price}</b>
      </label>
    </div>
    <div className="w-1/3">
      <input type="checkbox" id={varname} name={varname} onChange={(e)=> handler(e)}/>
    </div>
  </div>
);

function UpdateRegistration() {
  const { data, upgradableOptions, setError } = useContext(RegistrationContext);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [clientSecret, setClientSecret] = useState("");
  const [amount, setAmount] = useState(0);
  const [message, setMessage] = useState("");
  const [showPayment, setShowPayment] = useState(false);

  const clicked =  (option) => (e) =>{
    if (e.target.checked) {
        setSelectedOptions((prevOptions) => [...prevOptions, option]);
      } else {
        setSelectedOptions((prevOptions) => prevOptions.filter((opt) => opt !== option));
      }
  }

  const handleExtra = async () => {
    setError("");
    let clientSecret="";
    try {
      const response = await axios.post(`${base_url}/api/extra`, {
        options: selectedOptions,
      });
      setClientSecret(response.data.clientSecret);
      clientSecret = response.data.clientSecret;
      
    } catch (error) {
      
    }
    const stripe = await stripePromise;
    const {paymentIntent, error} = await stripe.retrievePaymentIntent(clientSecret);
    if (error) {
      // Handle error
      setError("Error retrieving payment details. Please try again.");
      return;
    }
    setAmount(paymentIntent.amount/100);
    setShowPayment(true);

  }

  function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      if (!stripe || !elements) {
           return;
      }
     
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: base_url + "/api/extra_payment/",
        },
      });
  
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
  
      
    };
  
    return (
      <form onSubmit={(e) => handleSubmit(e)} className="payment">
        <PaymentElement
                id="payment-element"
                options={{layout:"tabs"}}
              />
    
        <button type="submit" disabled={!stripe}>
        {"Pay now €" + amount   }
        </button>
        
        {message && <div id="payment-message">{message}</div>}

      </form>
    );
  }

  function StripeExtras() {
    // TO DO: STRIPE PAGE
    const isLoading= false;
    return (
      <div className="flex flex-col items-start">
       
  
        <div className="mt-4 w-full">
          <div className="w-full bg-gray-200 p-4 rounded-lg mx-auto text-center" style={{maxWidth:"500px"}}>
            <Elements options= {{clientSecret,appearance:{theme:"stripe"}}}stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full grow">
      <div
        style={{ maxWidth: "1152px", width: "100%" }}
        className="flex justify-start flex-col h-full items-center mx-auto lg:py-0 text-left"
      >
        <div
          style={{ maxWidth: "1152px", width: "100%", maxHeight: "100%" }}
          className="bg-white md:mt-0 sm:max-w-md xl:p-0  "
        >
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
            <h1 className="text-center px-10 pb-4 text-2xl">
              UPDATE REGISTRATION
            </h1>
            <div className="summary-card">
              Please select all the options that apply and click checkout to
              proceed with the payment.

              { upgradableOptions.map((option) => (
                <>
               <Opt
                description={option.description}
                price={option.price}
                varname={`var`+option.count}
                handler={clicked(option.count)}
              />
                </>
              ))}
              
              <div className="text-center">
                <button className="button mt-6 " onClick={() => handleExtra()} disabled={selectedOptions.length==0}>
                  Checkout
                </button>
                {showPayment && <StripeExtras />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateRegistration;
