import { useContext, useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { base_url } from "../../config";
import axios from "axios";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { CardActions, CardContent } from "@mui/material";
import RegistrationContext from "../../contexts/RegistrationContext";

export default function App() {

  const [data, setData] = useState([]);
  const [reload, setReload] = useState(0);
  const { setStatus } = useContext(RegistrationContext);

  const CodeCreation = () => {
    const createCode = async (type) => {
      const response = await axios.post(`${base_url}/api/admin/codes`, { type });

      setStatus("Code " + response.data.code + " sucessfully created")
      setReload(reload + 1);
    };

    const CodeCard = ({ title, description, btnTxt, btnCode }) => {
      return (
        <Card className="mb-8">
          <CardContent>
            <div className="flex flex-row">
              <div className="grow ">
                <div className="text-2xl mb-4">
                  {title}
                </div>
                <div className="font-normal">
                  {description.map((d, i) => <div key={i}>{d}</div>)}
                </div>
              </div>

              <div className=" w-60 ">
                <div className="flex justify-end w-full">
                  <div className="button text-sm " onClick={() => createCode(btnCode)}>
                    {btnTxt}
                  </div>
                </div>
              </div>
            </div>


          </CardContent>

        </Card >
      );

    }


    return (
      <>
        <div className="flex flex-col mb-8 gap-4">
          <CodeCard
            title={"Create VIP Code (FULL+BANQUET Reserved seat)"}
            description={["Gives access to everyting, with gala dinner included.  VIPs have a gala dinner seat reserved.", "The seat is freed if the VIP uses the code and opts out of the gala dinner."]}
            btnTxt={"Create VIP Code"}
            btnCode={"vip"}
          />
          <CodeCard
            title={"Create Invitation Code (FULL+BANQUET Subject to availability)"}
            description={["Gives access to everyting, with gala dinner included.  Invitation codes do not have a gala dinner seat reserved.", "If gala dinner is sold out, then the guest is not able to attend."]}
            btnTxt={"Create Invitation Code"}
            btnCode={"inv"}
          />
          <CodeCard
            title={"Create Volunteer Code (FULL)"}
            description={["Gives access to everyting, gala dinner not included."]}
            btnTxt={"Create Volunteer Code"}
            btnCode={"vol"}
          />
          <CodeCard
            title={"Create Weekend Code (WEEEKEND)"}
            description={["Gives access to the weekend. No access to main conference. Suitable for Workhsop/Tutorial organizers"]}
            btnTxt={"Create Weekend Code"}
            btnCode={"wkn"}
          />
          <CodeCard
            title={"Create Extra Code (MAIN + BANQUET)"}
            description={[" Gives access to the conference and gala dinner. No access to the weekend"]}
            btnTxt={"Create Extra Code"}
            btnCode={"ext"}
          />
            <CodeCard
            title={"Create USC Code"}
            description={[" Make registration zero to be billed internally by USC"]}
            btnTxt={"Create USC Code"}
            btnCode={"usc"}
          />
        </div >
      </>
    );
  }


  const FreshCodes = () => {
    const handleSent = async (code) => {
      try {
        const response = await axios.post(`${base_url}/api/admin/code_sent`, { code });
        setReload(reload + 1);
      } catch (error) {
        console.log(error);
      }
    };

    const handleDelete = async (code) => {
      try {
        const response = await axios.post(`${base_url}/api/admin/delete_code`, { code });
        setReload(reload + 1);
      } catch (error) {
        console.log(error);
      }
    };

    const columns = useMemo(
      () => [
        {
          accessorKey: "code", //simple recommended way to define a column
          header: "Code",
          muiTableHeadCellProps: { style: { color: "#444040" } }, //custom props
          enableHiding: false, //disable a feature for this column
        },

      ],
      []
    );
    const d = useMemo(() => data.filter(a => a.status === "unused"), [data]);
    const table = useMaterialReactTable({
      columns,
      data: d, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
      initialState: { density: "comfortable" },
      enableRowSelection: false, //enable some features
      enableColumnOrdering: true, //enable a feature for all columns
      enableGlobalFilter: true, //turn off a feature
      muiTableHeadCellProps: {
        // simple styling with the `sx` prop, works just like a style prop in this example
        // https://www.material-react-table.com/docs/guides/customize-components#the-sx-prop
        sx: {
          backgroundColor: "#e3f1ff",
        },
      },
      enableRowActions: true,
      positionActionsColumn: "last",
      renderRowActions: ({ row, table }) => (
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
          <button
            className="bg-teal-300 text-teal-700 font-bold py-2 px-4 rounded-sm "
            onClick={() => handleSent(row.original.code)}
          >
            Mark as sent
          </button>
          <button
            className="bg-red-300 text-red-700 font-bold py-2 px-4 rounded-sm "
            onClick={() => handleDelete(row.original.code)}
          >
            Delete
          </button>
        </Box>
      )
    });
    return (
      <MaterialReactTable table={table} className="h-full" />
    )
  }

  const SentCodes = () => {
    const handleUnSent = async (code) => {
      try {
        const response = await axios.post(`${base_url}/api/admin/code_unsent`, { code });
        setReload(reload + 1);
      } catch (error) {
        console.log(error);
      }
    };

    const columns = useMemo(
      () => [
        {
          accessorKey: "code", //simple recommended way to define a column
          header: "Code",
          muiTableHeadCellProps: { style: { color: "#444040" } }, //custom props
          enableHiding: false, //disable a feature for this column
        },
      ],
      []
    );
    const d = useMemo(() => data.filter(a => a.status === "sent"), [data]);
    const table = useMaterialReactTable({
      columns,
      data: d, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
      initialState: { density: "comfortable" },
      enableRowSelection: false, //enable some features
      enableColumnOrdering: true, //enable a feature for all columns
      enableGlobalFilter: true, //turn off a feature
      muiTableHeadCellProps: {
        // simple styling with the `sx` prop, works just like a style prop in this example
        // https://www.material-react-table.com/docs/guides/customize-components#the-sx-prop
        sx: {
          backgroundColor: "#e3f1ff",
        },
      },
      enableRowActions: true,
      positionActionsColumn: "last",
      renderRowActions: ({ row, table }) => (
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
          <button
            className="bg-red-300 text-red-700 font-bold py-2 px-4 rounded-sm "
            onClick={() => handleUnSent(row.original.code)}
          >
            Mark as not sent
          </button>
        </Box>
      )
    });
    return (
      <MaterialReactTable table={table} className="h-full" />
    )
  }

  const AssignedCodes = () => {
   

    const columns = useMemo(
      () => [
        {
          accessorKey: "code", //simple recommended way to define a column
          header: "Code",
          muiTableHeadCellProps: { style: { color: "#444040" } }, //custom props
          enableHiding: false, //disable a feature for this column
        },
        {
          accessorKey: "status", //simple recommended way to define a column
          header: "Status",
          muiTableHeadCellProps: { style: { color: "#444040" } }, //custom props
          enableHiding: false, //disable a feature for this column
        },
  
        {
          accessorKey: "email", //simple recommended way to define a column
          header: "Email",
          muiTableHeadCellProps: { style: { color: "#444040" } }, //custom props
          enableHiding: false, //disable a feature for this column
        },
        {
          accessorKey: "name", //simple recommended way to define a column
          header: "Name",
          muiTableHeadCellProps: { style: { color: "#444040" } }, //custom props
          enableHiding: false, //disable a feature for this column
        },
  
        // {
        //     accessorFn: (originalRow) => parseInt(originalRow.age), //alternate way
        //     id: 'age', //id required if you use accessorFn instead of accessorKey
        //     header: 'Age',
        //     Header: <i style={{ color: 'red' }}>Age</i>, //optional custom markup
        //     Cell: ({ cell }) => <i>{cell.getValue().toLocaleString()}</i>, //optional custom cell render
        // },
      ],
      []
    );
    const d = useMemo(() => data.filter(a => a.status === "active"), [data]);
    const table = useMaterialReactTable({
      columns,
      data: d, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
      initialState: { density: "comfortable" },
      enableRowSelection: false, //enable some features
      enableColumnOrdering: true, //enable a feature for all columns
      enableGlobalFilter: true, //turn off a feature
      muiTableHeadCellProps: {
        // simple styling with the `sx` prop, works just like a style prop in this example
        // https://www.material-react-table.com/docs/guides/customize-components#the-sx-prop
        sx: {
          backgroundColor: "#e3f1ff",
        },
      },
      
    });
    return (
      <MaterialReactTable table={table} className="h-full" />
    )
  }

  useEffect(() => {
    const loadData = async () => {
      const comm = await axios.get(`${base_url}/api/admin/codes`);
      setData([...comm.data]);
    };

    loadData();
  }, [reload]);

  const [value, setValue] = useState('one');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = (props) => {
    if (value === props.value) {
      return (
        <div className="mt-4 p-2">
          {props.children}
        </div>
      );
    }
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
        >
          <Tab
            value="one"
            label="Create codes"
            wrapped
          />
          <Tab value="two" label="Fresh codes" />
          <Tab value="three" label="Sent codes " />
          <Tab value="four" label="Assigned codes" />
        </Tabs>
      </Box>
      <TabPanel value="one"><CodeCreation /></TabPanel>
      <TabPanel value="two"><FreshCodes /></TabPanel>
      <TabPanel value="three"><SentCodes /></TabPanel>
      <TabPanel value="four"><AssignedCodes/></TabPanel>
    </>
  );

}