import React, { useContext } from 'react';
import axios from 'axios';
import RegistrationContext from '../contexts/RegistrationContext';
import setAuthToken from '../authtoken';
import { base_url } from '../config';
import { Drama } from 'lucide-react';

function Logout() {
    const { setScreen, setAuthenticated, setError, reload, setReload, setNext, setIsAdmin, setIsAdminScreen } = useContext(RegistrationContext);
    const handleImpersonate = async () => {
        setError('');
        try {
            await axios.post(`${base_url}/api/leave_impersonation`, {});
            setNext('admin-registrations');
            setReload(reload + 1);
        } catch (error) {
            console.error(error);
            setAuthenticated(false);
          //  localStorage.removeItem('token');
            setAuthToken(false);
            setScreen('login');
            setReload(reload + 1);
        }
    };
    return (
        <div className="flex gap-2 bg-pink-800 text-pink-200  px-4 py-1 rounded-md cursor-pointer" onClick={() => handleImpersonate()} >
            <Drama className="cursor-pointer text-pink-200" alt="Logout" size={24} />
            Leave impersonation
        </div>
    );
}

export default Logout;
