import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useContext } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import { base_url } from "../../config";
import axios from "axios";

export default function FreePayment() {

  const { setError, reload, setReload } =
    useContext(RegistrationContext);




  const handleSubmit = async (event) => {
    // Reset login error state
    event.preventDefault();
    setError("");


    try {
      // Make a POST request to your authentication endpoint
      const response = await axios.post(`${base_url}/api/progress`, {

      });
      setReload(reload + 1);
    } catch (error) {
      setError("Unexpected error. " + error.response.data.message);
    }
  };


  return (
    <>
      <div className="discountForm-wrapper flex justify-center mt-8">
        <div className="discountForm flex flex-col justify-between items-center gap-4 flex-col ">
          <h1>Proceed with your free registration</h1>
          <p>Your registartion is Free. Please click below to finalize your registration</p>

          <button onClick={(e) => handleSubmit(e)} className="buttonDiscount">
            Finalize
          </button>
        </div>
      </div>

    </>
  );
}
