export const base_url = process.env.REACT_APP_API_URL;
export const front_url = process.env.REACT_APP_FRONT_URL;
export const stripe_key = process.env.REACT_APP_STRIPE_KEY;
export const steps = [
  "Registration Type",
  "Workshop Selection",
  "Papers",
  "Extras",
  "Personal Data",
  "Payment",
  "Confirmation",
];
export const dates = [
  { early: { standard: 700, student: 500, single: 500 } },
  { regular: { standard: 820, student: 620, single: 620 } },
  { late: { standard: 920, student: 730, single: 730 } },
];
export const deadlines = [
  { early: "August 19th" },
  { regular: "October 3rd" },
  { late: "October 24th" },
];
