import { useContext } from "react";
import { deadlines } from "../../config";
import "./style.scss";
import RegistrationContext from "../../contexts/RegistrationContext";

function Footer() {
  const { phase, authenticated } = useContext(RegistrationContext);

  let day = "";
  for (const obj of deadlines) {
    const entries = Object.entries(obj);
    for (const [key, value] of entries) {
      if (phase === key) {
        day = value;
      }
    }
  }

  let phase_str = "";
  phase_str = phase;
  if (phase === "regular") {
    phase_str = "standard";
  }
  return (
    <div className="flex justify-center bg-footer text-white">
      <div className="footer"></div>
      <div></div>
      <div className="font-bold text-white">
        {phase !== "" && (
          <>
            {authenticated ? (
              <div className="flex">
                <div className="mr-5">
                  In {phase} registration until {day}
                </div>
                <div className="text-gray-300"> | </div>
                <div className="text-yellow-300 ml-5">
                  {" "}
                  <a href="mailto:registration@ecai2024.eu">
                    registration@ecai2024.eu
                  </a>
                </div>
              </div>
            ) : (
              <div className="flex">
                <div className="mr-5">
                  In {phase_str} registration until {day}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Footer;
