import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import RegistrationContext from "../contexts/RegistrationContext";
import { base_url } from "../config";
import countriesData from "../countries.json";
import WhiteBoxLayout from "../components/WhiteBoxLayout/WhiteBoxLayout";

import { useRef } from "react";

function PersonalData() {
  const {
    setError,
    reload,
    setReload,
    data,
    maxStep,
    screen,
    clickedNext,
    setClickedNext,
  } = useContext(RegistrationContext);
  const [fullName, setFullName] = useState(data.full_name);
  const [Affiliation, setAffiliation] = useState(data.affiliation);
  const [Affiliation2, setAffiliation2] = useState(data.affiliation2);
  const [recipientName, setRecipientName] = useState(data.recipient_name);
  const [institution, setInstitution] = useState(data.institution);
  const [Address1, setAddress1] = useState(data.address1);
  const [Address2, setAddress2] = useState(data.address2);
  const [taxID, setTaxID] = useState(data.tax_id);
  const [country, setCountry] = useState(data.country);
  const [gender, setGender] = useState(data.gender);
  const [registersPublication, setRegistersPublication] = useState(
    data.registers_publication
  );
  const [specialRequirements, setSpecialRequirements] = useState(
    data.special_requirements
  );
  const [sponsorsCheck, setSponsorsCheck] = useState(data.share_data_sponsors);

  const [check1, setCheck1] = useState(maxStep !== screen);

  const formRef = useRef();
  const submitBotton = useRef();

  console.log(data.share_data_sponsors);

  const handleSelect = async (event) => {
    // Reset login error state

    try {
      // Make a POST request to your authentication endpoint
      event.preventDefault();
      setClickedNext(0);
      await axios.post(`${base_url}/api/progress`, {
        full_name: fullName,
        affiliation: Affiliation,
        affiliation2: Affiliation2,
        recipient_name: recipientName,
        institution: institution,
        address1: Address1,
        address2: Address2,
        tax_id: taxID,
        country: country,
        registers_publication: registersPublication,
        gender: gender,
        special_requirements: specialRequirements,
        share_data_sponsors: sponsorsCheck,
      });
      setReload(reload + 1);
    } catch (error) {
      setError("Unexpected error. " + error.response.data.message);
    }
  };

  useEffect(() => {
    if (clickedNext == 0) return;
    submitBotton.current.click();
  }, [clickedNext]);

  return (
    <WhiteBoxLayout title={"Personal data"}>
      <div className="space-y-4 md:space-y-6">
        <form
          ref={formRef}
          method="POST"
          className="space-y-4 md:space-y-6"
          onSubmit={handleSelect}
        >
          <div className="md:flex md:gap-8">
            <button type="submit" ref={submitBotton} className="hidden">
              hidden
            </button>
            <div className="w-full my-4">
              <h1 className="pb-8 font-bold leading-tight tracking-tight text-gray-900 text-2xl ">
                Delegate info
              </h1>
              <div className="py-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Full name: <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                  placeholder="your full name"
                  required
                />
              </div>
              <div className="py-2">
                <label
                  htmlFor="Affiliation"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Affiliation: <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="Affiliation"
                  name="Affiliation"
                  placeholder="your affiliation"
                  value={Affiliation}
                  onChange={(e) => setAffiliation(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                  required
                />
              </div>
              <div className="py-2">
                <label
                  htmlFor="Affiliation2"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Affiliation 2:
                </label>
                <input
                  type="text"
                  id="Affiliation2"
                  name="Affiliation2"
                  placeholder="your affiliation"
                  value={Affiliation2}
                  onChange={(e) => setAffiliation2(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                />
              </div>

              <div className="items-center">
                <div className="text-sm my-2">
                  {" "}
                  <label htmlFor="gender">
                    Gender: <span className="text-red-600">*</span>
                  </label>
                </div>

                <div className="flex gap-8 bg-gray-50 border border-gray-300 px-2.5 py-0.5 mb-4">
                  <div className="py-2 flex items-center">
                    <input
                      id="gender-male"
                      type="radio"
                      name="gender"
                      value="male"
                      checked={gender === "male"} // Comprueba si la opción "yes" está seleccionada
                      onChange={() => setGender("male")} // Maneja el cambio de selección
                      className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 focus:ring-amber-500"
                      required
                    />
                    <label
                      htmlFor="gender-male"
                      className="ms-2 text-sm font-medium text-gray-900"
                    >
                      Male
                    </label>
                  </div>
                  <div className="py-2 flex items-center">
                    <input
                      id="gender-female"
                      type="radio"
                      name="gender"
                      value="female"
                      checked={gender === "female"}
                      onChange={() => setGender("female")}
                      className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500"
                      required
                    />
                    <label
                      htmlFor="gender-female"
                      className="ms-2 text-sm font-medium text-gray-900"
                    >
                      Female
                    </label>
                  </div>
                  <div className="py-2 flex items-center">
                    <input
                      id="gender-ns"
                      type="radio"
                      name="gender"
                      value="ns"
                      checked={gender === "ns"} // Comprueba si la opción "yes" está seleccionada
                      onChange={() => setGender("ns")} // Maneja el cambio de selección
                      className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500"
                      required
                    />
                    <label
                      htmlFor="gender-ns"
                      className="ms-2 text-sm font-medium text-gray-900"
                    >
                      Prefer not to say / Not listed
                    </label>
                  </div>
                </div>
              </div>

              <div className="pb-2">
                <label
                  htmlFor="specialRequirements"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Disabilities/Special Requirements/Dietary requirements
                </label>
                <input
                  type="text"
                  id="specialRequirements"
                  name="specialRequirements"
                  placeholder="If you have any special requirements, please let us know."
                  value={specialRequirements}
                  onChange={(e) => setSpecialRequirements(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                />
              </div>

              <div className="items-center">
                <div className="text-sm my-2">
                  {" "}
                  <label htmlFor="sponsors">
                    I agree to share my contact data with the conference
                    sponsors regarding activities related to ECAI 2024.:{" "}
                    <span className="text-red-600">*</span>
                  </label>
                </div>

                <div className="flex gap-8 bg-gray-50 border border-gray-300 px-2.5 py-0.5 mb-4">
                  <div className="py-2 flex items-center">
                    <input
                      id="sponsors-yes"
                      type="radio"
                      name="sponsors"
                      value="Yes"
                      checked={sponsorsCheck === "Yes"} // Comprueba si la opción "yes" está seleccionada
                      onChange={() => setSponsorsCheck("Yes")} // Maneja el cambio de selección
                      className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 focus:ring-amber-500"
                      required
                    />
                    <label
                      htmlFor="sponsors-yes"
                      className="ms-2 text-sm font-medium text-gray-900"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="py-2 flex items-center">
                    <input
                      id="sponsors-no"
                      type="radio"
                      name="sponsors"
                      value="No"
                      checked={sponsorsCheck === "No"}
                      onChange={() => setSponsorsCheck("No")}
                      className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500"
                      required
                    />
                    <label
                      htmlFor="sponsors-no"
                      className="ms-2 text-sm font-medium text-gray-900"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="py-2 flex items-center">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500"
                  required
                  onClick={() => setCheck1(!check1)}
                  checked={check1}
                />
                <p
                  htmlFor="default-checkbox"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  <a
                    className="text-blue-900"
                    href="https://www.ecai2024.eu/registration#policy"
                    target="blank"
                  >
                    Accept policy
                  </a>{" "}
                  <span className="text-red-600">*</span>
                </p>
              </div>
            </div>
            <div className="w-full my-4">
              <h1 className="pb-8 font-bold leading-tight tracking-tight text-gray-900 text-2xl ">
                Billing info
              </h1>
              <div className="py-2">
                <label
                  htmlFor="recipientName"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Recipient name:
                </label>
                <input
                  type="text"
                  id="recipientName"
                  name="recipientName"
                  value={recipientName}
                  onChange={(e) => setRecipientName(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                  placeholder="Recipient name"
                />
              </div>
              <div className="py-2">
                <label
                  htmlFor="institution"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Institution or Individual:{" "}
                  <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="institution"
                  name="institution"
                  value={institution}
                  onChange={(e) => setInstitution(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                  placeholder="institution"
                  required
                />
              </div>
              <div className="py-2">
                <label
                  htmlFor="Address1"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Address 1: <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="Address1"
                  name="Address1"
                  value={Address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                  placeholder="Address1"
                  required
                />
              </div>
              <div className="py-2">
                <label
                  htmlFor="Address1"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Address 2:
                </label>
                <input
                  type="text"
                  id="Address2"
                  name="Address2"
                  value={Address2}
                  onChange={(e) => setAddress2(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                  placeholder="Address2"
                />
              </div>
              <div className="py-2">
                <label
                  htmlFor="countries"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Country: <span className="text-red-600">*</span>
                </label>
                <select
                  id="countries"
                  name="country"
                  onChange={(e) => setCountry(e.target.value)}
                  defaultValue={country}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
                  required
                >
                  <option value="">--Select country--</option>
                  {countriesData.map((countr) => (
                    <option value={countr.code}>{countr.name}</option>
                  ))}
                </select>
              </div>
              <div className="py-2">
                <label
                  htmlFor="Address1"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  VAT:
                </label>
                <input
                  type="text"
                  id="taxID"
                  name="taxID"
                  value={taxID}
                  onChange={(e) => setTaxID(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                  placeholder="VAT"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </WhiteBoxLayout>
  );
}

export default PersonalData;
