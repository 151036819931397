import { useContext } from "react";
import AuthStatus from "../AuthStatus/AuthStatus";
import "./style.scss";
import RegistrationContext from "../../contexts/RegistrationContext";

const NavBar2 = () => {
  const { loading } = useContext(RegistrationContext);

  return <div className="navbar">{!loading && <AuthStatus />}</div>;
};

const NavBar = ({ boxed }) => {




  const { loading } = useContext(RegistrationContext);
  
  return (
    <div className={`navbar ${boxed && "boxed"}`}>
      
      {!loading && <AuthStatus />}
  
    </div>
  );
  };
export default NavBar;
